import React, { Component, useState, useEffect } from 'react';
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
//import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
//import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


import { authHeader, Loading, requestServer } from '../helpers';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { Button } from 'reactstrap';
import { faCheckCircle, faEye, faPlusCircle, faTimesCircle, faBackspace, faRecycle, faExchangeAlt, faExclamationTriangle, faReceipt, faShippingFast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyTable from './MyTable';



export function KalaList(props) {
    const [myrequests, setmyrequests] = useState([]);
    const [loading, setloading] = useState(false);
    const [bargashtiForm, setbargashtiForm] = useState(false)
    const [selectedId, setselectedId] = useState(0)
    const [formulKala, setformulKala] = useState(false)
    const [main, setmain] = useState(false)
    const [tab, setTab] = useState("tolidFormTab")



    useEffect(() => {
        populateWeatherData();
    }, []);

    const showBargashtiForm = (id, tab = "barghashtiTab") => {
        setTab(tab);
        setbargashtiForm(true);
        setselectedId(id);
    }

    const back = () => {
        setbargashtiForm(false);
        setformulKala(false);
        setmain(false);
    }

    const showFormul = (id) => {
        setformulKala(true);
        setselectedId(id);
    }

    const showMain = (id) => {
        setselectedId(id);
        setmain(true);
    }

    function showMainRequest(cell, row) {
        return (<Button className={"btn btn-secondary btn-sm"} style={{ width: 50 }} onClick={() => showMain(row.havaleNo)}><strong >{cell}</strong></Button>);
    }
    function meghdarFormatter(cell, row) {
        return (<Button onClick={() => showBargashtiForm(row.havaleNo, "tolidFormTab")} className={"btn btn-secondary btn-sm"} style={{ width: 50 }} ><strong >{cell}</strong></Button>);
    }
    function isApproveAnbarFormatter(cell, row) {
        return (<FontAwesomeIcon color={!row.isApproveAnbar ? "green" : "red"} size={"lg"} icon={(!row.isApproveAnbar ? faCheckCircle : faTimesCircle)} />);
    }
    function barghashtiFormatter(cell, row) {
        return (<span onClick={() => showBargashtiForm(row.havaleNo)} className={row.barghashtiCount > 0 ? "btn btn-success" : "btn btn-danger"}><FontAwesomeIcon size={"lg"} icon={faBackspace} /></span>);
    }
    function motamemCountFormatter(cell, row) {
        //"motamemTab"
        return (<span onClick={() => showBargashtiForm(row.havaleNo, "motamemTab")} className={row.motamemCount > 0 ? "btn btn-danger" : "btn btn-success"}><FontAwesomeIcon size={"lg"} icon={faPlusCircle} />{cell}</span>);
    }
    function zayeatFormatter(cell, row) {
        return (<span onClick={() => showBargashtiForm(row.havaleNo, "zayeatKhamirTab")} className={row.residZayeatCount > 0 ? "btn btn-success" : "btn btn-danger"}><FontAwesomeIcon size={"lg"} icon={faRecycle} />{cell}</span>);
    }
    function residFormatter(cell, row) {
        return (<span onClick={() => showBargashtiForm(row.havaleNo, "residTab")} className={row.residCount > 0 ? "btn btn-success" : "btn btn-danger"}><FontAwesomeIcon size={"lg"} icon={faShippingFast} />{cell}</span>);
    }
    function statusFormatter(cell, row) {
        var status = row.barghashtiCount > 0 ? 1 : row.residCount > 0 ? 2 : 3;
        var statusColor = status == 1 ? "btn btn-secondary" : status == 2 ? "btn btn-primary" : "btn btn-info";
        var statusText = status == 1 ? "برگشتی" : status == 2 ? "رسید" : "تولید";
        var statusIcon = status == 1 ? faExchangeAlt : status == 2 ? faReceipt : faExclamationTriangle;
        var selectTab = status == 1 ? "residTab" : status == 2 ? "barghashtiTab" : "tolidFormTab";
        return (<span onClick={() => showBargashtiForm(row.havaleNo, selectTab)} style={{ fontSize: 14 }} className={statusColor}><FontAwesomeIcon size={"sm"} icon={statusIcon} /> {statusText} </span>);
    }


    function kalaNameStyle(colum, colIndex) {
        return { width: '30%', textAlign: 'center' };
    }
    function showFormolFormatter(cell, row) {
        return (<span className="btn btn-warning"><FontAwesomeIcon onClick={() => showFormul(row.kalaId)} size={"lg"} icon={faEye} /></span>)
        return (<span color="info" className="btn btn-warning"><FontAwesomeIcon onClick={() => showFormul(row.kalaId)} color={"purple"} size={"lg"} icon={faEye} /></span>);
    }
    const columns = [
        { dataField: 'date', text: 'خط تولید' },
        { dataField: 'temperatureC', text: 'کد محصول' },
        { dataField: 'temperatureF', text: 'نام محصول', headerStyle: kalaNameStyle },
        { dataField: 'summary', text: 'فرمول ساخت', formatter: showFormolFormatter },
    ];

    const renderForecastsTable = (myrequests) => {
        return (
            <MyTable columns={columns} caption={"Saved List"} rows={[]} remoteData={{ address: "/WeatherForecast" }} />
        )
    }

    const populateWeatherData = async () => {
        try {
            var data = await requestServer("/WeatherForecast");
            setmyrequests(data);
            setloading(false);
            
        }
        catch (e) {
            setloading(false);
            props.history.push("/login");
            console.log(e);
        }
    }

    function contents() {
        let contents = loading
            ? Loading()
            : renderForecastsTable(myrequests);
        //contents = <div>test</div>;
        return (
            <div>
                {contents}
            </div>
        );
    }
    return (
        <div>{contents()}</div>
    )
}