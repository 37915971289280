import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import { profileData } from '../redux/Actions';
import * as auth from "../services/authService";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
class LogOutForm extends Component {
    username = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            admin: true, username: "", password: "",
            data: { username: "", password: "", token: "" },
            errors: "",
            isLoading: false,
            submitted: true,
            isLoggedOut: false
        };
    }
    componentDidMount = () => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user != null)
            this.setState({ username: user.username });
    };
    handleSubmit = e => {
        e.preventDefault();

        // call the server
        console.log("Password:" + this.state.password);
        //const username = this.username.current.value;
        console.log("Username:", this.state.username);
        this.doSubmit();
    };
    doSubmit = async () => {
        this.setState({ isLoading: true });
        await auth.logout();
        this.setState({ isLoading: false });
        this.props.saveProfileData({ username: undefined });
        this.setState({ isLoggedOut: true });
    };
    render() {
        if (this.state.isLoggedOut)
            return <Redirect to={'./login'} />
        return (
            <div className="auth-wrapper">
                <form onSubmit={this.handleSubmit}>
                    {this.state.isLoading && <div className="form-group">
                        <label htmlFor="errors" style={{ color: "red" }}>Please Wait . . .</label>
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>}
                    <div className="form-group">
                        <label htmlFor="errors" style={{ color: "red" }}>{this.state.errors}</label>
                    </div>
                    <div className="form-group text-left">
                        <label htmlFor="username">Username</label>
                        <input ref={this.username} readOnly={true} id="username" value={this.state.username} onChange={(e) => this.setState({ username: e.currentTarget.value })} type="text" className="form-control" />
                    </div>
                    <button className="btn btn-primary">LogOut</button>
                </form>
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => ({
    saveProfileData: (p) => dispatch(profileData(p))
})
const mapStateToProps = state => ({ profileData: state.application.profileData });
export default connect(mapStateToProps, mapDispatchToProps)(LogOutForm);