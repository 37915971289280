import { Input } from '@material-ui/core';
import React, { Component, useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AlertView from '../../components/AlertView';
import { BarcodeReaderTextInput } from '../../components/Barcode/BarcodeReaderTextInput'
import { StatusList } from '../../components/Status/StatusList'
import { profileData, setBadgeCount, showAlert } from '../../redux/Actions';
import { saveProductStatus, updateProductStatus, deleteProductStatus, getBeforeSaveStatus } from '../../services/barcodeScanPageService';
import { store } from './../_helpers';
import MyTable from '../../components/MyTable';
import { traceBarcode, traceBarcodeDetails, traceBarcodeLog, TracePurchaseOrder } from '../../services/resportService';
import styles from './../../styles/traceBarcodeReportStyle.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faRemoveFormat } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { getShipmentCompany, getShipmentLink, ReasonChangeList, RoleName } from '../../helpers';
import { connect } from 'react-redux';
import ScrollHelpers from '../../helpers/ScrollHelpers';
import ImageViewer from '../../components/ImageViewer';
function TraceBarcodeReport(props) {
    const [traceList, setTraceList] = useState([]);
    const [traceDetailsList, setTraceDetailsList] = useState([]);
    const [model, setModel] = useState({ productBarcodeNumber: "", parentBarcode: 0 });
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDetalsLoaded, setIsDetailsLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [isClient, setIsClient] = useState(true);
    const [isPoClient, setIsPoClient] = useState(false);
    const [isPackager, setIsPackager] = useState(true);
    const [focusBarcode, setFocusBarcode] = useState(true);
    const [msg, setMsg] = useState("");
    const [selectedBarcodeForHistory, setSelectedBarcodeForHistory] = useState();
    const [searchTypes, setSearchTypes] = useState([{ id: 1, title: "By Work Order" }, { id: 2, title: "By 3D Purchase Order" }]);
    const [selectedSearchType, setSelectedSearchType] = useState(1);
    const dvResult = useRef(null);
    useEffect(() => {
        //getInfo();

        if (!(props.profileData?.roles?.find(role => role === "CLIENT")))
            setIsClient(false);
        if ((props.profileData?.roles?.find(role => role === "POInquiry")))
            setIsPoClient(true);
        if ((props.profileData?.roles?.find(role => role === "PACKAGER")))
            setIsPackager(true);
    }, []);
    useEffect(() => {
        if (dvResult.current != null)
            ScrollHelpers.scrollTo(dvResult.current)
        else
            ScrollHelpers.scrollTop()
    }, [dvResult?.current])
    async function getInfo() {
        setMsg("");
        if (!model.productBarcodeNumber) {
            OnFocusBarcode();
            setMsg("Please input your work order");
            return;
        }
        setIsLoaded(false);
        setTraceDetailsList([]);
        setIsDetailsLoaded(false);
        var mainBarcode = model.productBarcodeNumber?.includes("-");
        console.log("NMM:: " + model.productBarcodeNumber)
        console.log(model.productBarcodeNumber?.includes("-"));
        var data = {
            ProductBarcodeNumber: mainBarcode ? model.productBarcodeNumber : "",
            ParentBarcode: mainBarcode ? 0 : parseFloat(model.productBarcodeNumber)
        };
        let res;
        if (selectedSearchType == 2) {
            data = { PurchaseOrder: model.productBarcodeNumber };
            res = await TracePurchaseOrder(JSON.stringify(data));
        }
        else {
            if (!data.ParentBarcode && !data.ProductBarcodeNumber) {
                OnFocusBarcode();
                setMsg("Please input valid work order");
                return;
            }
            res = await traceBarcode(JSON.stringify(data));
        }
        if (res) {
            setTraceList(res.data);
            if (res.data.length == 0)
                setMsg("Not found any result for " + model.productBarcodeNumber);
        }
        setIsLoaded(true);
        OnFocusBarcode();
    }
    function OnFocusBarcode() {
        setFocusBarcode(false)
        setTimeout(function () {
            setFocusBarcode(true)
        }, 1);
    }
    useEffect(() => {
        if (!focusBarcode)
            setFocusBarcode(true);
    }, [focusBarcode])
    async function clear() {
        setIsLoaded(false);
        setTraceList([]);
        setTraceDetailsList([]);
        setIsDetailsLoaded(false);
        setModel({ productBarcodeNumber: "", parentBarcode: 0 });
        setIsLoaded(true);
        OnFocusBarcode();
    }
    async function getDetailsInfo(mdl, isShowLog = false) {
        setIsDetailsLoaded(false);
        var mainBarcode = mdl.productBarcodeNumber?.includes("-");
        var data = {
            ProductBarcodeNumber: mainBarcode ? mdl.productBarcodeNumber : "",
            ParentBarcode: mainBarcode ? 0 : parseFloat(mdl.parentBarcode)
        };
        var res;
        if (isShowLog)
            res = await traceBarcodeLog(JSON.stringify(data));
        else
            res = await traceBarcodeDetails(JSON.stringify(data));
        if (res) {
            setTraceDetailsList(res.data);
        }
        setSelectedBarcodeForHistory(mdl.productBarcodeNumber);
        setIsDetailsLoaded(true);
        setShowModal(true);
    }
    function setModalCancel() {
        setShowModal(false);
    }
    function detailFormatter(cell, row) {
        return (<button className="btn btn-warning" onClick={() => getDetailsInfo(row)}>Details</button>);
    }
    const columns = [
        //{ dataField: 'userId', text: 'User' },
        //{ dataField: 'userIP', text: 'IP' },
        { dataField: 'stationName', text: 'Station' },
        { dataField: 'departmentName', text: 'Department' },
        { dataField: 'DepartmentUserName', text: 'DepartmentUser' },
        { dataField: 'productBarcodeNumber', text: 'Barcode' },
        { dataField: 'parentBarcode', text: 'ParentBarcode' },
        { dataField: 'createDate', text: 'CreateDate' },
        { dataField: 'description', text: 'Description' },
        { dataField: 'lastEffectedDateTime', text: 'Last Effected Date Time' },
        { dataField: 'statusId', text: 'Status' },
        { dataField: 'Details', text: 'Details', formatter: detailFormatter },
        { dataField: 'shipmentBarcode', text: 'shipmentBarcode' },
    ];
    const columnsDetails = [
        //{ dataField: 'userId', text: 'User' },
        //{ dataField: 'userIP', text: 'IP' },
        { dataField: 'stationName', text: 'Station' },
        { dataField: 'departmentName', text: 'Department' },
        { dataField: 'departmentUserName', text: 'DepartmentUser' },
        { dataField: 'productBarcodeNumber', text: 'Barcode' },
        { dataField: 'parentBarcode', text: 'ParentBarcode' },
        { dataField: 'createDate', text: 'CreateDate' },
        { dataField: 'description', text: 'Description' },
        { dataField: 'statusId', text: 'Status' },
        { dataField: 'shipmentBarcode', text: 'shipmentBarcode' },
        { dataField: 'lastEffectedDateTime', text: 'Last Effected Date Time' }
    ];
    return (
        <div>
            {msg && <div class={"text-center alert  alert-danger alert-dismissible fade show"} role="alert">
                <strong>{msg}</strong>
            </div>}
            <div className={styles.largeLabel}>Track Orders</div>
            <div className={styles.grayLabel}>Please enter product {selectedSearchType == 1 ? 'WO' : 'PO'} </div>
            <div style={{ width: "100%", marginTop: 10 }} className="row">
                {/* Barcode:{model.productBarcodeNumber}<br />
                Parent:{model.parentBarcode}<br /> */}
                <div>
                    {focusBarcode && <Input required placeholder="" className={styles.textBox} style={{


                        fontSize: "32px",
                        borderRadius: "10px",
                        letterSpacing: "7.11px",
                        borderColor: 'green',
                        borderStyle: "solid",
                        borderWidth: "3px"

                    }}
                        autoFocus={true}
                        value={model.productBarcodeNumber}
                        onKeyPress={
                            async (e) => {
                                if (e.charCode === 13)
                                    await getInfo();
                            }
                        }
                        onChange={(e) => {
                            setModel({ ...model, productBarcodeNumber: e.target.value, parentBarcode: 0 })
                        }} />}
                </div>
                <div className="row">
                    {isPoClient && <div>
                        < select
                            id={"searchType"}
                            value={selectedSearchType}
                            onChange={(event) => {
                                setSelectedSearchType(event.target.value)
                                OnFocusBarcode()
                            }}
                            style={{
                                borderColor: 'green',
                                borderStyle: "solid",
                                borderWidth: "3px"
                            }}
                            className={styles.comboBox}>
                            {searchTypes.map(p => {
                                return <option value={p.id}>{p.title}</option>
                            })
                            }
                        </select>
                    </div>}
                    <div className="">
                        <button type="button" className={styles.inquieryButton} onClick={getInfo}>Inquire Last Status</button>
                    </div><div className="">
                        <button type="button" className={styles.clearFormButton} onClick={clear}>Clear Form</button>
                    </div>
                </div>
            </div>
            {isLoaded && traceList?.length > 0 && < div ref={dvResult} className={styles.largeLabel}>Order Result :  <span className={styles.OrderResult}>{model.productBarcodeNumber}</span></div>}
            {
                traceList.map((item, i) => {
                    return (<div>
                        <div className={styles.boxResult}>
                            <div className="row">
                                <div>
                                    <div className={styles.ProductBarcode92}>Product Barcode : <span className={styles.value}>{item.productBarcodeNumber}</span>{item.purchaseOrder ? " PurchaseOrder :" : ""} {item.purchaseOrder && <span className={styles.value}> {item.purchaseOrder}</span>}</div>
                                </div>
                                <div className={styles.showHistoryLabel}>
                                    {((props.profileData?.roles?.find(role => role === RoleName.ADMIN || role===RoleName.SUPERVISOR)) || item.stationName=='SHIPPING') && <div className={styles.ShowProductHistory}><a href="#" onClick={() =>{setSelectedBarcodeForHistory(item.productBarcodeNumber); setShowImageModal(true)}}>Show Package Pictures</a></div>}
                                    <div className={styles.ShowProductHistory}><a href="#" onClick={() => getDetailsInfo(item)}>Show Work Order History</a></div>
                                    {(props.profileData?.roles?.find(role => role === RoleName.ADMIN)) && <span className={styles.showLogLabel} className={styles.ShowProductHistory}><a href="#" onClick={() => getDetailsInfo(item, true)}>Show Work Order Log</a></span>}
                                </div>
                            </div>
                            <div className="row">
                                <div className={styles.grayBox}>
                                    <div >Scan Date (Final Step):<span className={styles.value}>{moment(item.lastEffectedDateTime).locale('en').format("MMMM-DD-yyyy")} Time: {moment(item.lastEffectedDateTime).format("HH:mm")}</span></div>
                                </div>
                                <div className={styles.grayBox}>
                                    <div >Station: <span className={styles.value}>{item.stationName}</span></div>
                                </div>
                                <div className={styles.grayBox}>
                                    <div >Submenu:<span className={styles.value}>{item.departmentName}</span></div>
                                </div>
                                {!(props.profileData?.roles?.find(role => role === RoleName.CLIENT)) && <div className={styles.grayBox}>
                                    <div >Operator :<span className={styles.value}>{item.userName}</span></div>
                                </div>}
                                {item.shipmentBarcode && <div title={moment().diff(item.lastEffectedDateTime, 'hours') < 24 ? "(Your tracking number is available 24 hours after receiving this notification)" : ""} className={styles.grayBox}>
                                    {/* <div >ShipmentBarcode :<a target="_blank" href={getShipmentLink(item.shipmentBarcode)} className={styles.value}>{item.shipmentBarcode}</a></div> */}
                                    <div >{moment().diff(item.lastEffectedDateTime, 'hours') < 24 ? "*" : ""}ShipmentBarcode :<a target="_blank" href={item.shippingTraceLink?.replace('<TRACKNO>', item.shipmentBarcode)} className={styles.value}>{item.shipmentBarcode}</a></div>
                                </div>
                                }
                                {item.shippingCompanyName &&
                                    <div className={styles.grayBox}>
                                        {/* <div >Shipping Company  :<span className={styles.value}>{getShipmentCompany(item.shipmentBarcode)}</span></div> */}
                                        <div >Shipping Company  :<span className={styles.value}>{item.shippingCompanyName}</span></div>
                                    </div>
                                }
                                {item.trackingType &&
                                    <div className={styles.grayBox}>
                                        <div >Tracking Type  :<span className={styles.value}>{item.trackingType}</span></div>
                                    </div>
                                }
                                {item.itemTitle3 && <div className={styles.grayBox}>
                                    <div >{item.itemLabel3}:<span className={styles.value}> {item.itemTitle3}</span></div>
                                </div>}
                                {item.itemTitle2 && <div className={styles.grayBox}>
                                    <div >{item.itemLabel2}:<span className={styles.value}> {item.itemTitle2}</span></div>
                                </div>}
                                {(item.itemTitle || item.departmentName == "Queued") && <div className={styles.grayBox}>
                                    <div >{item.departmentName == "Queued" && !item.itemTitle ? "Product Menu" : item.itemLabel}:<span className={styles.value}> {item.departmentName == "Queued" && !item.itemTitle ? "N/A" : item.itemTitle}</span></div>
                                </div>}
                                {item.eta && <div className={styles.grayBox}>
                                    <div >ETA: <span className={styles.value}>{moment(item.eta).locale('en').format("MMMM-DD-yyyy")}</span></div>
                                </div>}
                                {/* <span title='This item is new record' style={{ color: "red" }}>{(item.isNewRecord ? " (NR)" : "")}</span> */}
                            </div>
                            <div className={styles.memo}>{item.description}</div>
                        </div>
                    </div>)
                })
            }
            <Modal
                show={showModal}
                //show={true}
                onHide={() => setModalCancel()}
                className="text-left"
                size="xl"
            >
                <Modal.Body>
                    <div className={styles.topModal}>
                        <div className={styles.closeButton} onClick={setModalCancel}>X</div>
                        <div className={styles.largeLabelCenter} style={{ textAlign: "center" }}>Product History</div>
                        <div className={styles.ProductBarcode92Center}>WorkOrder Barcode : {selectedBarcodeForHistory}</div>
                    </div>
                    {
                        traceDetailsList.map((item, i) => {
                            return (<div>
                                <div className={styles.boxResult} style={{ margin: 0 }}>
                                    <div className="row">
                                        <div className={styles.grayBox}>
                                            <div >Scan Date:<span className={styles.value}>{moment(item.lastEffectedDateTime).locale('en').format("MMMM-DD-yyyy")} Time: {moment(item.lastEffectedDateTime).format("HH:mm")}</span></div>
                                        </div>
                                        <div className={styles.grayBox}>
                                            <div >Station: <span className={styles.value}>{item.stationName}</span></div>
                                        </div>
                                        <div className={styles.grayBox}>
                                            <div >Submenu:<span className={styles.value}>{item.departmentName}</span></div>
                                        </div>
                                        {!(props.profileData?.roles?.find(role => role === RoleName.CLIENT)) && <div className={styles.grayBox}>
                                            <div >Operator :<span className={styles.value}>{item.userName}</span></div>
                                        </div>}
                                        {item.departmentUserName && <div className={styles.grayBox}>
                                            <div >C.S. Name :<span className={styles.value}>{item.departmentUserName}</span></div>
                                        </div>}
                                        {item.shipmentBarcode && <div title={moment().diff(item.lastEffectedDateTime, 'hours') < 24 ? "(Your tracking number is available 24 hours after receiving this notification)" : ""} className={styles.grayBox}>
                                            <div>ShipmentBarcode :<a target="_blank" href={item.shippingTraceLink?.replace('<TRACKNO>', item.shipmentBarcode)} className={styles.value}>{item.shipmentBarcode}</a></div>
                                        </div>
                                        }
                                        {item.shippingCompanyName &&
                                            <div className={styles.grayBox}>
                                                <div >Shipping Company  :<span className={styles.value}>{item.shippingCompanyName}</span></div>
                                            </div>
                                        }
                                        {item.trackingType &&
                                            <div className={styles.grayBox}>
                                                <div >Tracking Type  :<span className={styles.value}>{item.trackingType}</span></div>
                                            </div>
                                        }
                                        {item.reasonChange > 0 &&
                                            <div className={styles.grayBox}>
                                                <div >Reason Change  :<span className={styles.value}>{ReasonChangeList.filter(p => p.id == item.reasonChange)[0]?.title}</span></div>
                                            </div>
                                        }
                                        {item.operationTypeTitle &&
                                            <div className={styles.grayBox}>
                                                <div >Operation Type  :<span className={styles.value}>{item.operationTypeTitle}</span></div>
                                            </div>
                                        }
                                        {item.itemTitle3 && <div className={styles.grayBox}>
                                            <div >{item.itemLabel3}:<span className={styles.value}> {item.itemTitle3}</span></div>
                                        </div>}
                                        {item.itemTitle2 && <div className={styles.grayBox}>
                                            <div >{item.itemLabel2}:<span className={styles.value}> {item.itemTitle2}</span></div>
                                        </div>}
                                        {(item.itemTitle || item.departmentName == "Queued") && <div className={styles.grayBox}>
                                            <div >{item.departmentName == "Queued" && !item.itemTitle ? "Product Menu" : item.itemLabel}:<span className={styles.value}> {item.departmentName == "Queued" && !item.itemTitle ? "N/A" : item.itemTitle}</span></div>
                                        </div>}
                                        {item.eta && <div className={styles.grayBox}>
                                            <div >ETA: <span className={styles.value}>{moment(item.eta).locale('en').format("MMMM-DD-yyyy")}</span></div>
                                        </div>}
                                        {/* <span title='This item is new record' style={{ color: "red" }}>{(item.isNewRecord ? " (NR)" : "")}</span> */}
                                    </div>
                                    <div className={styles.memo}>{item.description}</div>
                                </div>
                            </div>)
                        })
                    }
                </Modal.Body>
            </Modal>

            {isPackager && <Modal
                show={showImageModal}
                //show={true}
                onHide={() => setShowImageModal(false)}
                className="text-center"
                size="xl"
            >
                <Modal.Body style={{background:"linear-gradient(to right,#6190E8,#A7BFE8)"}}>
                    <div className={styles.topModal}>
                        <div className={styles.closeButton} onClick={() => setShowImageModal(false)}>X</div>
                        <div className={styles.largeLabelCenter} style={{ textAlign: "center" }}>Package Pictures WO: {selectedBarcodeForHistory}</div>
                    </div>
                    <ImageViewer productBarcodeNumber={selectedBarcodeForHistory} />
                </Modal.Body>
            </Modal>}

            {/* {isLoaded && <MyTable type="cartable" columns={columns} noSearch="true" rows={traceList} />}
            {isDetalsLoaded && <MyTable type="cartable" columns={columnsDetails} noSearch="true" rows={traceDetailsList} />} */}
        </div >
    )
}
const mapDispatchToProps = dispatch => ({
    saveProfileData: (p) => dispatch(profileData(p)),
    setBadgeCount: (data) => dispatch(setBadgeCount(data))
})
const mapStateToProps = state => ({
    profileData: state.application?.profileData,
    badgeCount: state.application?.badgeCount,
});
export default connect(mapStateToProps, mapDispatchToProps)(TraceBarcodeReport);