const initialState = {
    profileData: {},
    loading: false,
    message: null,
    msgType: null,
    isLoggedIn: false,
    badgeCount: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "PROFILE_DATA":
            return { ...state, profileData: action.data };
        // return {
        //     ...state,
        //     profileData: { ...action.data }
        // };

        case "SHOW_LOADER":
            return { ...state, loading: true };

        case "HIDE_LOADER":
            return { ...state, loading: false };

        case "SHOW_ALERT":
            return { ...state, message: action.message, msgType: action.msgType };
        case "IS_LOGGEDIN":
            return { ...state, isLoggedIn: false };
        case "SET_BADGE_COUNT":
            return { ...state, badgeCount: action.data };
        case "INC_BADGE_COUNT":
            return { ...state, badgeCount: state.badgeCount + 1 };
        default:
            return state;
    }
};
