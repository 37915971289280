import { config } from "../helpers";

const apiEndpoint = config.apiUrl + "/api/users";

// export function login(email, password) {
//   return http.post(apiEndpoint + "/Authenticate", { email, password });
// }
export async function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(config.apiUrl + '/api/users/Authenticate', requestOptions)
        .then(handleResponse, handleError)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem("token", user.token);
                console.log(user.token);
            }

            return user;
        });
}
export async function signup(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(config.apiUrl + '/api/users/Register', requestOptions)
        .then(handleResponse, handleError)
        .then(user => {
            console.log(user);
            return user;
        });
}
export function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
}
function handleResponse(response) {
    console.log("Response:" + response);
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

function handleError(error) {
    console.log("Error:" + error);
    return Promise.reject(error && error.message);
}