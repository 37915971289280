import React, { Component } from "react";
import { connect } from "react-redux";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import styles from '../assets/css/content.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { showAlert } from "../redux/Actions";
import { Redirect } from "react-router-dom";
import { store } from "../redux/Store";
const closeCommand = "CLOSE";
const btnOk = React.createRef();
class AlertView extends Component {
    componentDidUpdate(){
        try{
        this.btnOk.focus()
        }
        catch{}
    }
    state = { isAuthorized: true };
    setModalClose() {
        store.dispatch(showAlert(this.props.message, closeCommand));
        if (this.props.msgType == "GO_LOGIN")
            this.setState({ isAuthorized: false });
    }
    render() {
        const { message } = this.props;
        if (!message) return null;
        if (!this.state.isAuthorized)
            return <Redirect to={'./login'} />
        return (
            <Modal
                show={this.props.msgType != closeCommand}
                onHide={() => this.setModalClose()}
                className="text-left"
            >
                <Modal.Header>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body><p className={styles.error} style={{ color: this.props.msgType == "danger" ? "red" : "" }}>{this.props.message}</p></Modal.Body>
                <Modal.Footer>
                    <Button ref={(btn) => { this.btnOk = btn }} autoFocus variant={(this.props.msgType != "GO_LOGIN" ? this.props.msgType : "info")} onClick={() => this.setModalClose()}>
                        OK
  </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    message: state.application?.message,
    msgType: state.application?.msgType,
    profileData: state.application?.profileData
});//

export default connect(mapStateToProps)(AlertView);