import React, { useState, useEffect } from 'react';

const MathCaptcha = ({onChangeStatus}) => {
  const [firstOperand, setFirstOperand] = useState(0);
  const [secondOperand, setSecondOperand] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [captchaText, setCaptchaText] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);

    setFirstOperand(num1);
    setSecondOperand(num2);
    setCaptchaText(`${num1} + ${num2} = `);
  };

  const handleChange = (event) => {
    const userAnswer=event.target.value;
    setUserAnswer(event.target.value)
    const expectedAnswer = firstOperand + secondOperand;
    const userEnteredAnswer = parseInt(userAnswer, 10);

    if (userEnteredAnswer === expectedAnswer) {
      onChangeStatus(true);
    } else {
        onChangeStatus(false);
      // Optionally, you can handle incorrect answers differently (e.g., show an error message).
    }
  };

  return (
    <div style={{alignItems:'end',textAlign:'center',display:'flex',paddingLeft:5}}>
        <h1 style={{marginRight:0,width:100,fontSize:20,textAlign:'left'}}>
          {captchaText}
        </h1>
        {/* <TextField type="text" label={'Captcha answer'} style={{width:'100%'}}  value={userAnswer} onChange={handleChange} /> */}
        <input type='text' style={{width:'100%',fontSize:20}} placeholder='Captcha answer' className='form-control' value={userAnswer} onChange={handleChange} />
    </div>
  );
};

export default MathCaptcha;
