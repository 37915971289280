import { Input } from '@material-ui/core';
import React, { Component, useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AlertView from '../components/AlertView';
import BarcodeReader from 'react-barcode-reader'
import { BarcodeReaderTextInput } from '../components/Barcode/BarcodeReaderTextInput'
import { StatusList } from '../components/Status/StatusList'
import { showAlert } from '../redux/Actions';
import { saveProductStatus, updateProductStatus, deleteProductStatus, getBeforeSaveStatus } from '../services/barcodeScanPageService';
import { store } from '../redux/Store';
import styles from '../styles/barcodeScanStyle.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode, faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import MyTable from '../components/MyTable';
import { getShipmentCompanyCode, isShipmentBarcode, myCheckIsNumber, ReasonChangeList } from '../helpers';
import ToggleSwitch from '../components/ToggleSwitch/ToggleSwitch';
export function BarcodeScanPage() {
    const initData = [{ id: 1, title: "Damage", isSelected: false }, { id: 2, title: "Repair", isSelected: false }, { id: 3, title: "OK", isSelected: true }];
    const initData2 = [{ id: 1, title: "Damage", isSelected: false }, { id: 2, title: "Repair", isSelected: true }, { id: 3, title: "OK", isSelected: false }];
    const initData3 = [{ id: 1, title: "Damage", isSelected: true }, { id: 2, title: "Repair", isSelected: false }, { id: 3, title: "OK", isSelected: false }];
    const [data, setData] = useState(initData);
    const [status, setStatus] = useState(3);
    const [barcode, setBarcode] = useState("");
    const [description, setDescription] = useState();
    const [focusBarcode, setFocusBarcode] = useState(true);
    const [temp, setTemp] = useState("");
    const [result, setResult] = useState("");
    const [showAlertNotify, setShowAlertNotify] = useState(false);
    const [savedList, setSavedList] = useState([]);;// useState([{ "ProductBarcodeNumber": "445451" }, { "ProductBarcodeNumber": "445452" }, { "ProductBarcodeNumber": "445453" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445455" }, { "ProductBarcodeNumber": "445456" }, { "ProductBarcodeNumber": "445457" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" }, { "ProductBarcodeNumber": "445454" },]);
    const [operationMode, setOperationMode] = useState(1);///1=Add, 2=Update,3=Delete
    const [editEntity, setEditEntity] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [selectedDepartmentId, setSelectedDepartmentId] = useState();
    const [selectedDepartment, setSelectedDepartment] = useState({});

    const [selectedTrackingType, setSelectedTrackingType] = useState(1);
    const [trackingTypes, setTrackingTypes] = useState([{ id: 1, title: "Automatic Tracking" }, { id: 2, title: "Manual Tracking" }, { id: 3, title: "NO Tracking" }]);
    const [reasonChangeList, setReasonChangeList] = useState(ReasonChangeList);
    const [readOnlyQueuedMenu, setReadOnlyQueuedMenu] = useState();
    const [reasonChange, setReasonChange] = useState(0);

    const [selectedDepartmentUserId, setSelectedDepartmentUserId] = useState({});
    const [selectedDepartmentItem, setSelectedDepartmentItem] = useState(4);
    const [submenuItems, setSubmenuItems] = useState([]);
    const [selectedDepartmentUser, setDepartmentUser] = useState({});

    const [shipmentBarcode, setShipmentBarcode] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [shippmentCo, setShippmentCo] = useState(0);
    const [shippingCompanies, setShippingCompanies] = useState([]);
    const [tempShippingCompanies, setTempShippingCompanies] = useState([]);
    const [msg, setMsg] = useState("");
    const [isFetch, setIsFetch] = useState(false);
    const [counter, setCounter] = useState(0);
    const [preMainBarcode, setPreMainBarcode] = useState("");
    const [res, setRes] = useState('No Scan');
    const [rowNumber, setRowNumber] = useState(1);
    const [customInput, setCustomInput] = useState(false);
    const [customBarcode, setCustomBarcode] = useState();
    useEffect(() => {
        if (barcode)
            saveStatus()
    }, [barcode])
    useEffect(() => {
        if (shipmentBarcode && selectedTrackingType == 1) {
            var co = getShipmentCompanyCode(shipmentBarcode);
            if (co > 0)
                setShippmentCo(co);
        }
    }, [shipmentBarcode])
    // useEffect(() => {
    //     async function fetchMyAPI() {
    //         try {
    //             var regex = /\d{6}/;
    //             var val = "";
    //             if (regex.test(description)) {
    //                 val = regex.exec(description);
    //                 alert(val)
    //                 await handleScan(val);
    //             }
    //             else if (selectedDepartment.title == 'Shipped') {
    //                 var regex2 = /[P,B,U]\d{6}/;
    //                 if (regex2.test(description)) {
    //                     val = regex2.exec(description);
    //                     await handleScan(val);
    //                 }
    //             }
    //         } catch(e) { alert(e)}
    //     }
    //     if (description)
    //         fetchMyAPI()
    // }, [description])
    async function handleScan(result) {
        if (result) {
            await onBarcodeChange(result);
            //await saveStatus();
        }
    }
    function handleError(err) {
        console.error(err)
    }
    async function onSelect(id) {
        gotFocusBarcode();
        setStatus(id);
    }
    async function onBarcodeChange(barcodeNumber) {
        if (selectedDepartment.title == "Shipped" && isShipmentBarcode(barcodeNumber)) {
            if (selectedTrackingType > 1) {
                store.dispatch(showAlert("The scanned barcode(" + barcodeNumber + ") is not valid", "danger"));
                return;
            }
            setShipmentBarcode("")
            setTimeout(function () {
                setShipmentBarcode(barcodeNumber)
            }, 100);
        }
        else if (!(isShipmentBarcode(barcodeNumber))) {
            setBarcode("")
            setTimeout(function () {
                setBarcode(barcodeNumber);
            }, 100);
        }
        else {
            setMsg("")
            setTimeout(function () {
                setMsg("The " + barcodeNumber + " Is not in shipped or ship barcode or (Not Shipped but its shipment barcode)");
            }, 50);
        }
    }
    async function onBarcodeKeyPress(e) {
        setTemp(e.charCode);
        if (e.charCode === 13)
            await saveStatus();
    }
    function gotFocusBarcode() {
        setFocusBarcode(false);
        setTimeout(function () {
            setFocusBarcode(true);
        }, 100);
    }
    async function saveStatus(op) {
        setShowAlertNotify(false);
        if (!validate()) {
            gotFocusBarcode();
            return;
        }
        let selectedDepartmentItemId = getSelectedSubMenuItem()
        var object = {
            ProductBarcodeNumber: barcode + "", StatusId: status, Description: description,
            StationId: selectedDepartment.stationId, DepartmentId: selectedDepartmentId,
            DepartmentType: selectedDepartment.departmentType,
            ShipmentBarcode: shipmentBarcode, DepartmentUserId: selectedDepartmentUserId,
            ShippedStatus: selectedDepartment.title != 'Shipped' ? 0 : selectedTrackingType,
            ShippedCompany: shippmentCo, ReasonChange: reasonChange,
            DepartmentItemId: selectedDepartmentItemId,
            StationGroupId: selectedDepartment.stationGroupId
        };
        var data = JSON.stringify(object);
        var result;
        if (operationMode === 3 || op === 3) {
            object.id = editEntity.id;
            data = JSON.stringify(object);
            result = await deleteProductStatus(data);
        }
        else if (operationMode === 2) {
            object.id = editEntity.id;
            data = JSON.stringify(object);
            result = await updateProductStatus(data);
        }
        else {
            result = await saveProductStatus(data);
        }
        if (result?.status === 0) {
            setIsLoaded(false);
            setCustomBarcode("");
            object.operation = result.message;
            object.result = "Ok";
            object.rowNumber = rowNumber;
            setRowNumber(rowNumber + 1)
            setSavedList([...savedList, object]);
            setResult(result);
            setShippmentCo(0);
            setShipmentBarcode();
            if (selectedDepartment.title === 'Shipped')
                setSelectedTrackingType(1);
            setIsLoaded(true);
            initStateComponents();
            setOperationMode(1);
        }
        else if (result?.status == -2) {////Update Mode
            var st = result.data.statusId;
            setResult(null);
            setIsFetch(true);
            setSelectedTrackingType(result.data.shippedStatus)
            setShippmentCo(result.data.shippedCompany)
            setBarcode(result.data.productBarcodeNumber)
            setPreMainBarcode(result.data.productBarcodeNumber)
            setDescription(result.data.description)
            setShipmentBarcode(result.data.shipmentBarcode)
            setReasonChange(result.data.reasonChange)
            if (result.message)
                setMsg(result.message)
            if (result.departmentItemInQueued)
                setReadOnlyQueuedMenu(result.departmentItemInQueued);
            console.warn("Extra", result.extraData);
            if (result.extraData) {
                let tmp = [...submenuItems];
                result.extraData.reverse().map((item, index) => {
                    // tmp[item.level].selectedId = item.selectedId;
                    // tmp[item.level].departmentItems = item.departmentItems;
                    handleOnChangeSubmenuItem(item.selectedId, submenuItems[item.level])

                });
                console.warn("Temp New", tmp);
                //setSubmenuItems(tmp);
            }
            //alert(result.data.departmentUserId);
            if (result.data.departmentUserId)
                setSelectedDepartmentUserId(result.data.departmentUserId);
            setData(st === 1 ? initData3 : st === 2 ? initData2 : st === 3 ? initData : []);
            setStatus(st);
            gotFocusBarcode();
            setShowConfirmModal(true)
            setEditEntity(result.data);
            return;
        }
        else
            setResult(result);
        if (selectedDepartment.title === 'Shipped') {
            if (result != undefined) {
                setCounter(0)
            }
            else {
                setCounter(counter + 1);
            }
            if (counter >= 2) {
                setOperationMode(1);
                setCounter(0)
            }
        }
        else
            setOperationMode(1);
        setShowAlertNotify(true);
        //window.location.reload(false);
        //alert(JSON.stringify(result))

    }
    function initStateComponents() {
        setStatus(3);
        setBarcode("");
        setShipmentBarcode("");
        setData([]);
        setData(initData);
        setDescription("");
        gotFocusBarcode();
        setReasonChange(0)
        if (selectedDepartment.title === 'Shipped') {
            setSelectedTrackingType(1);
            setShippmentCo(0);
            setCounter(0);
        }
    }
    function validate() {
        setMsg("");
        if (selectedDepartment.isRequiredMemo && !description) {
            setMsg(selectedDepartment.requiredMemoMessage);
            store.dispatch(showAlert(selectedDepartment.requiredMemoMessage));
            return false;
        }
        let msgIsSelected = checkForSelectedItems();
        if (msgIsSelected) {
            setMsg(msgIsSelected);
            store.dispatch(showAlert(msgIsSelected));
            return false;
        }
        // if (selectedDepartment.title === 'Shipped' && shippmentCo < 1 && operationMode==2) {
        //     setBarcode("");
        //     setMsg("Please Select Shipping Company");
        //     store.dispatch(showAlert("Please Select Shipping Company"));
        //     return false;
        // }
        // if (selectedDepartment.title === 'Shipped' && operationMode==2 && selectedTrackingType!=3 && (shipmentBarcode=="" || shipmentBarcode==undefined)) {
        //     setBarcode("");
        //     setMsg("Please Input shipmentBarcode");
        //     store.dispatch(showAlert("Please Input shipmentBarcode"));
        //     return false;
        // }
        if (operationMode == 2 && preMainBarcode != barcode) {
            store.dispatch(showAlert("The scanned workorder is not match with previous barcode (" + preMainBarcode + " VS " + barcode + ")", "danger"));
            return false;
        }
        // if (selectedDepartment.title === 'Shipped' && selectedTrackingType == 2 && !myCheckIsNumber(shipmentBarcode)) {
        //     store.dispatch(showAlert("The scanned barcode(" + shipmentBarcode + ") is not valid", "danger"));
        //     setShipmentBarcode("");
        //     return false
        // }
        if (barcode.length > 0)
            return true;
        else
            setMsg("Please Scan Work Order");
        return false;
    }
    function setModalCancel() {
        setOperationMode(1);
        initStateComponents();
        setShowConfirmModal(false);
        gotFocusBarcode();

    }
    function setModalUpdate() {
        setOperationMode(2);
        setShowConfirmModal(false);
        gotFocusBarcode();
    }
    function setModalDelete() {
        setOperationMode(3);
        saveStatus(3);
        setShowConfirmModal(false);
        gotFocusBarcode();
    }
    useEffect(() => {
        async function getInfo() {
            var result = await getBeforeSaveStatus();
            if (result?.status == 0) {
                setUserInfo(result.data);
                if (result.data.departments[0]?.stationName?.toLowerCase() === "shipping")
                    setSelectedDepartmentId(result.data.departments[2]?.id);
                else
                    setSelectedDepartmentId(result.data.departments[0]?.id);
                //if (setSelectedDepartmentUserId(result.data.departments[0]?.departmentUsers?.length > 0))
                //    setSelectedDepartmentUserId(result.data.departments[0]?.departmentUsers[0]?.id);
                setShippingCompanies(result.data.shippingCompanies);
                setTempShippingCompanies(result.data.shippingCompanies);
            }
        }
        getInfo();
    }, [])
    useEffect(() => {
        if (!isFetch || operationMode == 2) {
            setShippmentCo(0)
        }
        else
            setIsFetch(false);////In Duplicate Mode
        if (selectedTrackingType == 2) {
            let co = tempShippingCompanies.filter(p => !p.isShowInAuto);
            setShippingCompanies(co);
        }
        else {
            setShippingCompanies(tempShippingCompanies);
        }

    }, [selectedTrackingType])
    useEffect(() => {
        setReasonChange(0);
        setReadOnlyQueuedMenu();
        if (selectedDepartmentId == undefined)
            return;
        var res = userInfo?.departments?.filter(p => p.id == selectedDepartmentId);
        if (res.length > 0) {
            setSelectedDepartment(res[0]);

            ///Test
            initDropDowns(res);
            ///Test
            if (res[0].title === 'Shipped') {
                setSelectedTrackingType(1);
            }
            else {
                setSelectedTrackingType(0);
                setShippmentCo(0);
                setShipmentBarcode("");
            }
            if (res[0].title === 'Customer Service' && res[0].departmentUsers?.length > 0)
                setSelectedDepartmentUserId(res[0].departmentUsers[0]?.id);
            else
                setSelectedDepartmentUserId();
        }
    }, [selectedDepartmentId])
    const columns = [
        { dataField: 'rowNumber', text: 'No' },
        { dataField: 'ProductBarcodeNumber', text: 'Barcode' },
        { dataField: 'operation', text: 'Operation' },
        { dataField: 'result', text: 'Result' }
    ];
    const initDropDowns = (res) => {

        //let obj1 = { level: 0, selectedId: res[0].departmentItems.filter(p => p.isDefault)[0]?.id ?? 0, departmentItems: res[0].departmentItems.filter(p => p.level == 0) };
        let tmp = [];
        //tmp.push(obj1);
        let grp = groupArrayOfObjects(res[0].departmentItems, "level");
        let counter = 0
        let parent = null;
        Object.keys(grp).map((key, index) => {
            let item = res[0].departmentItems.filter(p => p.isDefault && p.level == key);
            if (counter >= 1)
                return;
            //alert(JSON.stringify(item) + key + "P:" + parent)
            let subItems = res[0].departmentItems.filter(p => p.level == key && p.parentId == parent);
            let obj = {};
            obj.level = key;
            obj.selectedId = item[0]?.id ?? 0;
            obj.departmentItems = subItems;
            parent = item[0]?.id ?? null;
            if (subItems.length > 0)
                tmp.push(obj);
            let subIndex = subItems.findIndex(p => p.sameLevel > 0);
            if (subIndex > -1) {
                //alert(JSON.stringify(subItems[subIndex]) + key + "P:" + parent + "Sub:" + subIndex)
                obj = {};
                obj.level = subItems[subIndex].level + 1;
                obj.selectedId = 0;
                obj.departmentItems = subItems[subIndex].departmentItems;
                tmp.push(obj);
            }
            if (item.length == 0 || subItems.length == 0)
                counter++;
        });
        setSubmenuItems(tmp)
        //alert(JSON.stringify(res[0].departmentItems.filter(p => p.isDefault && p.level == 0)));
        //setSubmenuItems([{ level: 0, selectedId: res[0].departmentItems.filter(p => p.isDefault)[0]?.id ?? 0, departmentItems: res[0].departmentItems.filter(p => p.level == 0) }])
        //setSubmenuItems([{ level: 0, selectedId: res[0].departmentItems.filter(p => p.isDefault && p.level == 0)[0]?.id ?? 0, departmentItems: res[0].departmentItems.filter(p => p.isDefault && p.level == 0) }])
    }
    function groupArrayOfObjects(array, key) {
        if (array == undefined)
            return;
        return array
            .reduce((hash, obj) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
            }, {})
    };
    const handleOnChangeSubmenuItem = (selectedValue, item) => {
        let childs = selectedDepartment.departmentItems?.filter(p => p.parentId == selectedValue);
        let childLevel = childs[0]?.level;
        let tmp = [...submenuItems];
        tmp = tmp.filter(p => p.level <= item.level + 1);
        let existLevel = submenuItems.findIndex(p => p.level == childLevel);
        let defaultId = childs.filter(p => p.isDefault)[0]?.id ?? 0;
        tmp[item.level].selectedId = selectedValue;
        //console.warn("TMP:", tmp, childLevel, selectedValue, item)
        if (existLevel > -1) {
            if (!tmp[childLevel]?.departmentItems) {
                let obj = {};
                obj.level = childLevel;
                //obj.selectedId =  0;
                obj.departmentItems = childs;
                tmp.push(obj);
                setSubmenuItems(tmp)
            }
            else {
                tmp[childLevel].departmentItems = childs;
                setSubmenuItems(tmp)
            }
        }
        else if (childLevel)
            setSubmenuItems(pre => [...pre, { level: childLevel, selectedId: defaultId, departmentItems: childs }]) //Add Menu
        else {
            //alert("tt:"+tmp[item.level + 1]?.departmentItems.length+"SD:"+tmp[item.level]?.departmentItems.length)
            if ((tmp[item.level + 1]?.departmentItems.length > 0)) {
                tmp.pop();
            }
            setSubmenuItems(tmp)
        }
        gotFocusBarcode();
    };
    const getSelectedSubMenuItem = () => {
        let max = Math.max.apply(null, submenuItems.map(p => p.level));
        setSelectedDepartmentItem(submenuItems[max]?.selectedId);
        return submenuItems[max]?.selectedId;
    }
    const checkForSelectedItems = () => {
        let msg;
        for (var i = 0; i < submenuItems.length; i++) {
            let item = submenuItems[i];
            if (item.selectedId == 0) {
                let label = item.departmentItems[0]?.label;
                msg = "Please select your " + label;
                break;
            }
        }
        return msg;
    }
    return (
        <div class="container-fluid">
            {msg != "" && <div class={result === undefined ? "text-center alert  alert-success alert-dismissible fade show" : "text-center alert  alert-danger alert-dismissible fade show"} role="alert">
                <strong>{msg}</strong>
            </div>}
            <div className={styles.toggleSwitch}><ToggleSwitch label="Manual Barcode Entry" onCHange={(e) => setCustomInput(e.target.checked)} /></div>
            <div className="row">
                <div className={styles.topScanLogLeft}>
                    <div className="row">
                        <div className={styles.ProductBarcode}>
                            <FontAwesomeIcon size={"sm"} icon={faBarcode} /> Work Order Barcode :
                            {/* <FontAwesomeIcon style={{ cursor: "pointer" }} size={"sm"} icon={customInput ? faCheckCircle : faCircle} color={customInput ? "green" : "gray"} onClick={() => setCustomInput(!customInput)} /> */}
                            {/* onClick={() => handleScan('76544')} */}
                        </div>

                        {focusBarcode && <button className={styles.ProductBarcodeText} autoFocus={focusBarcode}>{barcode}</button>}
                        {/* {focusBarcode ? <BarcodeReaderTextInput onBarcodeChange={onBarcodeChange} value={barcode} focus={focusBarcode} onKeyPress={onBarcodeKeyPress} /> : ""} */}

                    </div>
                    <div className={styles.normalLabel}>
                        Please {!customInput ? "scan" : "input"} work order barcode
                    </div>
                    {customInput && <div className="row">
                        <p>
                            <Input autoFocus={true} style={{
                                width: "250px",
                                padding: "10px",
                                fontSize: "16px",
                                borderRadius: "10px",
                                letterSpacing: "7.11px",
                                marginTop: "-12px",
                            }}
                                required value={customBarcode} onChange={(e) => {
                                    setCustomBarcode(e.target.value)
                                }} />
                        </p>
                        <p>
                            <button type="button" className={styles.sendButton} onClick={() => handleScan(customBarcode)}>Send</button>
                        </p>
                    </div>}
                    <hr />
                    <div>
                        {userInfo?.departments?.length > 0 &&
                            <div className="row">
                                <p className={styles.normalLabel}>Station :</p>
                                <p className="btn btn-info">{selectedDepartment != undefined ? selectedDepartment.stationName : ""}{operationMode == 2 ? " *" : ""}</p>
                            </div>
                        }

                        <hr />
                    </div>
                    <div className={styles.normalLabel}>
                        Select Submenu:
                    </div>
                    {userInfo?.departments?.length > 0 &&
                        <div>
                            <div >
                                < select
                                    id={"departmentSelect"}
                                    value={selectedDepartmentId}
                                    onChange={(event) => {
                                        gotFocusBarcode();
                                        setSelectedDepartmentId(event.target.value)
                                        setMsg("");
                                    }
                                    }
                                    onKeyPress={gotFocusBarcode}
                                    className={styles.comboBox}>
                                    {userInfo.departments.map(p => {
                                        return <option value={p.id}>{p.title}</option>
                                    })
                                    }
                                </select>
                            </div>
                            {readOnlyQueuedMenu &&
                                <div className="col-md-6">
                                    <div className={styles.normalLabel}>
                                        {readOnlyQueuedMenu.label}
                                    </div>
                                    < select
                                        id={"reasonChangeListSelectQueue"}
                                        disabled
                                        value={readOnlyQueuedMenu}
                                        className={styles.comboBox}>
                                        <option value={readOnlyQueuedMenu.id}>{readOnlyQueuedMenu.title}</option>
                                    </select>
                                </div>}
                            <div className="row">
                                {selectedDepartment.title === 'Shipped' &&
                                    <div className="col-md-6">
                                        <div className={styles.normalLabel}>
                                            Select Tracking Type
                                        </div>
                                        < select
                                            id={"trackingTypeSelect"}
                                            value={selectedTrackingType}
                                            onChange={(event) => {
                                                setShipmentBarcode();
                                                //if (event.target.value != 2)
                                                gotFocusBarcode();
                                                setSelectedTrackingType(event.target.value)
                                            }}
                                            onKeyPress={gotFocusBarcode}
                                            className={styles.comboBox}>
                                            {trackingTypes?.map(p => {
                                                return <option value={p.id}>{p.title}</option>
                                            })
                                            }
                                        </select>
                                    </div>}
                                {selectedDepartment.title === 'Shipped' &&
                                    <div className="col-md-6">
                                        <div className={styles.normalLabel}>
                                            Select Shipping Company
                                        </div>
                                        < select
                                            id={"shippingCompnySelect"}
                                            value={shippmentCo}
                                            onChange={(event) => {
                                                // setShipmentBarcode();
                                                setShippmentCo(event.target.value)
                                                if (selectedTrackingType != 2)
                                                    gotFocusBarcode();
                                            }}
                                            disabled={selectedTrackingType == 1}
                                            onKeyPress={gotFocusBarcode}
                                            className={styles.comboBox}>
                                            <option value="0">Select...</option>
                                            {shippingCompanies?.map(p => {
                                                return <option value={p.id}>{p.title}</option>
                                            })
                                            }
                                        </select>
                                    </div>}
                            </div>
                            <div className="row">
                                {selectedDepartment?.departmentUsers?.length > 0 &&
                                    <div className="col-md-6">
                                        <div className={styles.normalLabel}>
                                            Select User
                                        </div>
                                        < select
                                            id={"departmentUserSelect"}
                                            value={selectedDepartmentUserId}
                                            onChange={(event) => {
                                                gotFocusBarcode();
                                                setSelectedDepartmentUserId(event.target.value)


                                            }}
                                            className={styles.comboBox}>
                                            {selectedDepartment.departmentUsers?.map(p => {
                                                return <option value={p.id}>{p.title}</option>
                                            })
                                            }
                                        </select>
                                    </div>}

                                {selectedDepartment.title === 'Customer Service' &&
                                    <div className="col-md-6">
                                        <div className={styles.normalLabel}>
                                            Select Reason Change
                                        </div>
                                        < select
                                            id={"reasonChangeListSelect"}
                                            value={reasonChange}
                                            onChange={(event) => {
                                                gotFocusBarcode();
                                                setReasonChange(event.target.value)
                                            }}
                                            className={styles.comboBox}>
                                            <option value="0">Select...</option>
                                            {reasonChangeList?.map(p => {
                                                return <option value={p.id}>{p.title}</option>
                                            })
                                            }
                                        </select>
                                    </div>}



                                {/*TESTTTTTTTTT First*/}

                                {submenuItems?.map((item, index) => {
                                    return <div className="col-md-6">
                                        <div className={styles.normalLabel}>
                                            {item.departmentItems[0]?.label}
                                            {/* Product Menu */}
                                        </div>
                                        < select
                                            id={"departmentItemSelect"}
                                            value={submenuItems[item.level]?.selectedId}
                                            onChange={(event) => handleOnChangeSubmenuItem(event.target.value, item)}
                                            className={styles.comboBox}>
                                            {item.departmentItems.findIndex(p => p.isDefault) == -1 && <option value="0">Please Select ...</option>}
                                            {item.departmentItems?.map(p => {
                                                return <option value={p.id}>{p.title} {p.subLevel}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                })}
                                {/* <div><button onClick={() => {
                                    // let grp = groupArrayOfObjects(submenuItems, "level");
                                    // //getSelectedSubMenuItem();
                                    // handleOnChangeSubmenuItem(35,submenuItems[0])
                                    console.warn(submenuItems);
                                }
                                } className="btn btn-warning">Test</button></div> */}
                                {/*TESTTTTTTTTT First END





                            </div>
                        </div>}
                    <hr />
                    {selectedDepartment.title === 'Shipped' && selectedTrackingType != 3 && <div className="row">
                        <div className={styles.ProductBarcode} style={{ fontSize: "26px" }}>
                            <FontAwesomeIcon size={"sm"} icon={faBarcode} /> Shipment Barcode :
                        </div>
                        <div className={styles.ShipProductBarcodeText}>
                            {selectedTrackingType != 2 && shipmentBarcode}
                            {/* {(selectedTrackingType == 2 || shippmentCo==-1) && <div> */}
                                {(shippmentCo > 0 && selectedTrackingType == 2 ? true : false) && <div>
                                    <Input autoFocus={true} style={{
                                        width: "250px",
                                        //border: "3px solid blue",
                                        padding: "10px",
                                        fontSize: "16px",
                                        borderRadius: "10px",
                                        letterSpacing: "7.11px",
                                        marginTop: "-12px",
                                    }}
                                        onKeyPress={(e) => {
                                            if (e.charCode == 13) {
                                                // if (!myCheckIsNumber(shipmentBarcode)) {
                                                //     setShipmentBarcode("");
                                                //     store.dispatch(showAlert("The scanned barcode(" + shipmentBarcode + ") is not valid", "danger"));
                                                // }
                                                gotFocusBarcode();
                                            }
                                        }}
                                        required value={shipmentBarcode} onChange={(e) => {
                                            //if (e.target.value.startsWith('B') || e.target.value.startsWith('P') || e.target.value.startsWith('U'))
                                            //if (myCheckIsNumber(e.target.value))
                                            setShipmentBarcode(e.target.value)
                                        }} /></div>}
                            </div>
                        </div>}
                    {selectedDepartment.title === 'Shipped' && selectedTrackingType != 3 && <div className={styles.normalLabel}>
                        Please scan shipment barcode
                        <hr />
                    </div>

                    }

                    <div>
                        <textarea className={styles.memo}
                            placeholder="Memo ..." onChange={(e) =>
                                setDescription(e.currentTarget.value)
                            } value={description} />
                    </div>

                </div>
                <div className={styles.topScanLog}>
                    <div className={styles.normalLabel}>
                        Top Scan Log
                    </div>
                    <div className={styles.logTable}>
                        {isLoaded && <MyTable columns={columns} noRowNumber={true} noSearch="true" rows={savedList.slice(0).reverse()} />}
                        {/* {isLoaded && <MyTable columns={columns} noSearch="true" rows={savedList} />} */}
                    </div>
                </div>

                {/*TESTTTTTTTTT */}
                {/* {submenuItems?.length > 0 && <div>{JSON.stringify(submenuItems)} {"SelectedItem:" + selectedDepartmentItem}</div>} */}
                {/*TESTTTTTTTTT END*/}
            </div>

            {showAlertNotify && <div style={{ marginLeft: "10%" }} class={result != undefined ? "col-md-4 text-center alert  alert-success alert-dismissible fade show" : "col-md-4 text-center alert  alert-danger alert-dismissible fade show"} role="alert">
                <strong>{result != undefined ? "Saved Success !!!" : "Fault !!!"}</strong>
            </div>}
            {/* {focusBarcode ? <StatusList data={data} onSelect={onSelect} /> : ""} */}
            <BarcodeReader
                onError={handleError}
                onScan={handleScan}
                minLength={3}
            />
            <Modal
                show={showConfirmModal}
                onHide={() => setModalCancel()}
                className="text-left"
            >
                <Modal.Header>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body><p style={{ color: "this.props.msgType" == "danger" ? "red" : "" }}>Duplicate</p></Modal.Body>
                <Modal.Footer>
                    <Button variant={"success"} onClick={() => setModalCancel()}>Cancel</Button>
                    <Button variant={"warning"} onClick={() => setModalUpdate()}>Update</Button>
                    <Button variant={"danger"} onClick={() => setModalDelete()}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}