import React, { Component, useState, useEffect } from 'react';
import './MyTable.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { requestServer } from '../../src/helpers';
import { faRedoAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from 'reactstrap';
const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;
export default function MyTable(props) {
    const pageSize = props.pageSize ?? 5;
    const [errorMessage, seterrorMessage] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getInfo();
    }, []);
    const getInfo = async () => {
        seterrorMessage();
        if (props.remoteData?.address == null)
            return;
        var result = await requestServer(props.remoteData.address, props.remoteData.data, props.remoteData.method);
        if (result) {
            setRows(result);
            try {
                if (props.type == "cartable")
                    props.setBadge(result.length);
            }
            catch {
            }
        }
        else
            seterrorMessage("Could not connect to server");
    };
    const radifColumn = [
        {
            dataField: 'radif',
            text: 'No',
            style: {
                width: "18px"
            },
            attrs: { title: 'id column', width: "5px" },
            headerStyle: (colum, colIndex) => {
                return { width: '50px', textAlign: 'center' };
            },
            formatter: radifFormatter,
            hidden:props.noRowNumber
        }];

    function radifFormatter(cell, row, rowIndex) {
        // let rowNumber = (currentPage - 1) * 5 + (rowIndex + 1);
        // return <span>{rowNumber}</span>;
        return (
            <p>{(rowIndex + 1) + (pageSize * (currentPage-1))}</p>
            // RW={rowIndex+1} pg={pageSize} CR={currentPage}
        );
    }
    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (rowIndex % 2 == 0) {
            style.backgroundColor = '#fff';
        } else {
            style.backgroundColor = '#EEF7EE';
        }
        style.fontSize = 14;
        return style;
    };
    const columns = radifColumn.concat(props.columns);
    const [rows, setRows] = useState(props.rows);
    //const rows = props.rows;
    const CaptionElement = () => props.caption ? <h5 className="text-center" style={{ width: "20%", position: "absolute", top: "10%", left: 20, borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>{props.caption}</h5> : "";
    //   {groupName} شیفت {shift}
    const options = {
        // pageStartIndex: 0,
        sizePerPage: pageSize,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        onPageChange: page => {
            setCurrentPage(page)
        }
    };
    useEffect(() => {
    }, [currentPage])

    return (
        <div>
            <ToolkitProvider
                keyField="id"
                data={rows}
                columns={columns}
                search
                bootstrap4
            >
                {
                    prop => (
                        <div className="text-center">
                            <div className="text-right">
                                {!props.noSearch && <div>
                                    <SearchBar placeholder="Search" {...prop.searchProps} />
                                    <ClearSearchButton text="X" className="btn btn-info" {...prop.searchProps} />
                                    <span className="btn btn-secondary" style={{ cursor: "pointer", zIndex: 1000 }}><FontAwesomeIcon onClick={() => getInfo()} size={"sm"} icon={faRedoAlt} /></span>
                                </div>}
                                <div>
                                    <Alert color="danger" isOpen={errorMessage != null} toggle={() => seterrorMessage()}>
                                        <strong>{errorMessage}</strong>
                                    </Alert>
                                </div>
                                <BootstrapTable
                                    // selectRow={selectRow}
                                    pagination={paginationFactory(options)}
                                    rowStyle={rowStyle}
                                    caption={<CaptionElement />}
                                    {...prop.baseProps}
                                    noDataIndication="No data"
                                />
                            </div>


                        </div>

                    )
                }
            </ToolkitProvider>
        </div>
    )
}