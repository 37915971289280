import { requestServer } from "../helpers";

export async function saveProductStatus(data)
{
    var data = await requestServer("/api/Product/SaveStatus",data,"POST");
    return data;
}
export async function updateProductStatus(data)
{
    var data = await requestServer("/api/Product/UpdateStatus",data,"POST");
    return data;
}
export async function deleteProductStatus(data)
{
    var data = await requestServer("/api/Product/DeleteStatus",data,"POST");
    return data;
}
export const getBeforeSaveStatus = async () => {
    var data = await requestServer("/api/Product/BeforeSaveStatus");
    return data;
}