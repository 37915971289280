import { requestServer } from "../helpers";

export const traceBarcode = async (data) => {
    var data = await requestServer("/api/Report/TraceBarcode",data,"POST");
    return data;
}
export const TracePurchaseOrder = async (data) => {
    var data = await requestServer("/api/Report/TracePurchaseOrder",data,"POST");
    return data;
}
export const traceBarcodeDetails = async (data) => {
    var data = await requestServer("/api/Report/TraceBarcodeDetails",data,"POST");
    return data;
}
export const traceBarcodeLog = async (data) => {
    var data = await requestServer("/api/Report/TraceBarcodeLog",data,"POST");
    return data;
}
export const getDepartmentsStatus = async (data) => {
    var data = await requestServer("/api/Report/GetDepartmentsStatus",data,"POST");
    return data;
}
export const getReportLevel2 = async (data) => {
    var data = await requestServer("/api/Report/GetReportLevel2",data,"POST");
    return data;
}