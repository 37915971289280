import { Input } from '@material-ui/core'
import React, { Component } from 'react'
import BarcodeReader from 'react-barcode-reader'

export class BarcodeReaderTextInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      result: '',
    }

    this.handleScan = this.handleScan.bind(this)
  }
  handleScan(result) {
    if (result) {
      this.setState({ result })
    }
  }
  handleError(err) {
    console.error(err)
  }
  render() {
    return (
      <div>
        <Input style={{
          width:"280px",
          //border: "3px solid blue",
          padding: "10px",
          fontSize: "32px",
          borderRadius: "10px",
          letterSpacing:"7.11px",
          marginTop:"-12px",
        }} type="text"
          //placeholder="scan barcode . . ."
          autoFocus={this.props.focus}
          onChange={(e) => this.props.onBarcodeChange(e.currentTarget.value)}
          value={this.props.value}
          required={true}
          onKeyPress={this.props.onKeyPress}
        />
        
      </div>
    )
  }
}