import { Input } from '@material-ui/core';
import React, { Component, useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import MyTable from '../../components/MyTable';
import { getDepartmentsStatus, getStationsStatus, traceBarcode, traceBarcodeDetails, getReportLevel2 } from '../../services/resportService';
import { PieChart } from 'react-minimal-pie-chart';
import randomColor from "randomcolor";
import moment from 'moment';
import Chart from 'react-google-charts';
import { Pie3D } from 'react-pie3d';
import stylesTrace from './../../styles/traceBarcodeReportStyle.module.css';
import styles from './../../styles/departmentsStatusPage.module.css';
import ApexCharts from 'apexcharts';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(Highcharts);
export function DepartmentsStatusPage() {
    const [traceList, setTraceList] = useState([]);
    const [pieDataLevel0, setPiedataLevel0] = useState([]);
    const [pieDataLevel00, setPiedataLevel00] = useState([]);
    const [pieDataLevel1, setPiedataLevel1] = useState([]);
    const [traceDetailsList, setTraceDetailsList] = useState([]);
    const [model, setModel] = useState({ productBarcodeNumber: "", parentBarcode: 0 });
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedLevel1, setIsLoadedLevel1] = useState(false);
    const [isDetalsLoaded, setIsDetailsLoaded] = useState(false);
    const [date, setDate] = useState(new Date());
    const [showModal, setShowModal] = useState(false);
    const [selectedStation, setSelectedStation] = useState();
    useEffect(() => {
        getInfo();
    }, []);
    useEffect(() => {
        if (pieDataLevel0.length > 0)
            tst();
    }, [isLoaded])
    function setModalCancel() {
        setShowModal(false);
    }
    async function getInfo() {
        setIsLoaded(false);
        setTraceDetailsList([]);
        setPiedataLevel1([]);
        setIsDetailsLoaded(false);
        var data = {
            Date: date,
            level: 0
        };
        var res = await getDepartmentsStatus(JSON.stringify(data));
        if (res) {
            setTraceList(res.data);
            var tmp = [];
            var tmp2 = [['Task', 'Hours per Day']];
            res.data.map((item, index) => {
                let color = randomColor();
                tmp.push({ id: item.id, title: item.title, value: item.count, color: color, label: item.title });
                tmp2.push([item.title, item.count]);
            });
            console.log("TESSSSS", tmp)
            setPiedataLevel0(tmp);
            setPiedataLevel00(tmp2);
        }
        setIsLoaded(true);
    }
    async function getInfoLevel1(item) {
        setSelectedStation(item);
        setIsLoadedLevel1(false);
        setTraceDetailsList([]);
        //setPiedataLevel1([]);
        setIsDetailsLoaded(false);
        var data = {
            Date: date,
            StationGroupId: item.id,
            level: 1
        };
        var res = await getDepartmentsStatus(JSON.stringify(data));
        if (res) {
            setTraceList(res.data);
            var tmp = [];
            res.data.map((item, index) => {
                let color = randomColor();
                tmp.push({ id: item.id, title: item.title, value: item.count, label: item.title, color: color });//, color: color
            });
            setPiedataLevel1(tmp);
        }
        setIsLoadedLevel1(true);
    }
    async function getDetailsInfo(mdl) {
        console.log("detail:", selectedStation);
        setIsDetailsLoaded(false);
        var mainBarcode = mdl.productBarcodeNumber?.includes("-");
        var data = {
            id: mdl.id,
            Date: date,
            DepertmentGroupId: mdl.id,
            StationGroupId: selectedStation.id
        };
        var res = await getReportLevel2(JSON.stringify(data));
        
        console.log("OUTdetail2:", res);
        if (res) {
            setTraceDetailsList(res.data);
            setShowModal(true);
        }
        setIsDetailsLoaded(true);
    }
    function detailFormatter(cell, row) {
        return (<button className="btn btn-warning" onClick={() => getDetailsInfo(row)}>Details</button>);
    }
    function dateFormatter(cell, row) {
        return (<span>{moment(cell).locale('en').format("MMMM-DD-yyyy")} {moment(cell).format("HH:mm")}</span>);
    }
    const columns = [
        { dataField: 'title', text: 'Title' },
        { dataField: 'count', text: 'Count' },
        { dataField: 'Details', text: 'Details', formatter: detailFormatter }
    ];
    const columnsDetails = [
        //{ dataField: 'userId', text: 'User' },
        //{ dataField: 'userIP', text: 'IP' },
        { dataField: 'stationName', text: 'Station' },
        { dataField: 'departmentName', text: 'Submenu' },
        { dataField: 'productBarcodeNumber', text: 'Work Order' },
        { dataField: 'userName', text: 'User' },
        { dataField: 'createDate', text: 'CreateDate', formatter: dateFormatter },
        { dataField: 'lastEffectedDateTime', text: 'Last Effected Date Time', formatter: dateFormatter },
        { dataField: 'departmentUserName', text: 'CustomerServiceUser' },
        { dataField: 'description', text: 'Description' }
    ];
    let myStyle = {
        position: "absolute", marginTop: -20, fontWeight: "bold"
    }
    var options = {
        series: pieDataLevel0.map((e, i) => { return e.value }),
        chart: {
            //width: 380,
            type: 'pie',
        },
        labels: pieDataLevel0.map((e, i) => { return e.title }),
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };
    var optionsDonut = {
        series: pieDataLevel0.map((e, i) => { return e.value }),
        chart: {
            //width: 380,
            type: 'donut',
        },
        labels: pieDataLevel0.map((e, i) => { return e.title }),
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };
    function tst() {
        if (document.querySelector("#chart") === null)
            return;
        var chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render()

        if (document.querySelector("#chartDonut") === null)
            return;
        var chartDonut = new ApexCharts(document.querySelector("#chartDonut"), optionsDonut);
        chartDonut.render()
    }
    const config = {
        angle: 40,
        h: 40,
        //ir: 60,
        //size: 40,
        onSliceClick: d => {
            console.log(d);
        }
    }
    const salesData = [
        { label: 'JIVE', color: 'blue', value: 10 },
        { label: 'NRCIA', color: 'red', value: 400 },
        { label: 'SPHS', color: 'limegreen', value: 100 },
        { label: 'TSLA', color: 'mediumpurple', value: 100 },
        { label: 'ISLE', color: 'orange', value: 150 },
        { label: 'SLAB', color: 'firebrick', value: 100 }
    ];
    const chartOptions = {
        chart: {
            type: "pie",
            options3d: {
                enabled: true,
                alpha: 45,
                beta: 0
            }
        },
        title: {
            text: "Work orders in stations"
        },
        tooltip: {
            pointFormat: "{series.name}: ({point.y}) <b>{point.percentage:.1f}%</b>"
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                depth: 35,
                dataLabels: {
                    enabled: true,
                    format: "{point.name}"
                }
            }
        },
        series: [
            {
                type: "pie",
                name: "Stations",
                data: pieDataLevel0.map((e, i) => { return [e.title, e.value, e.id] }),
                point: {
                    events: {
                        click: function (event) {
                            getInfoLevel1(pieDataLevel0[this.index])
                        }
                    }
                }
                // data: [
                //   ["Firefox", 45.0],
                //   ["IE", 26.8],
                //   ["Chrome",23],
                //   ["Safari", 8.5],
                //   ["Opera", 6.2],
                //   ["Others", 0.7]
                // ]
            }
        ]
    }
    const chartOptionsLevel1 = {
        chart: {
            type: "pie",
            options3d: {
                enabled: true,
                alpha: 45,
                beta: 0
            }
        },
        title: {
            text: "Submenu of Work orders in "+selectedStation?.title
        },
        tooltip: {
            pointFormat: "{series.name}: ({point.y}) <b>{point.percentage:.1f}%</b>"
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                depth: 35,
                dataLabels: {
                    enabled: true,
                    format: "{point.name}"
                }
            }
        },
        series: [
            {
                type: "pie",
                name: "Submenu",
                data: pieDataLevel1.map((e, i) => { return [e.title, e.value] }),
                point: {
                    events: {
                        click: function (event) {
                            getDetailsInfo(pieDataLevel1[this.index])
                        }
                    }
                }
                // data: [
                //   ["Firefox", 45.0],
                //   ["IE", 26.8],
                //   ["Chrome",23],
                //   ["Safari", 8.5],
                //   ["Opera", 6.2],
                //   ["Others", 0.7]
                // ]
            }
        ]
    }
    return (
        <div style={{}} class="container-fluid">
            <div className="text-center">
                <input
                    value={moment(date).format("YYYY-MM-DD")}
                    onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))}
                    type="date"
                />
                {/* <span>  {moment(date).locale('en').format("MMMM-DD-yyyy")}</span> */}
                <span className="btn btn-success" style={{ margin: 20 }} onClick={getInfo}>Search</span>
                {/* <p>{moment(date).format("YYYY-DD-MM")}</p> */}

            </div>
            <div className={styles.rowC}>
                <div className={styles.partframe}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </div>
                {pieDataLevel1.length > 0 && isLoadedLevel1 && <div style={{ height: 380 }} className={styles.partframe}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptionsLevel1}
                    />
                </div>}
            </div>
            {isLoaded && <div className={styles.rowC}>
                <div className={styles.partframe}>
                    <span style={myStyle}>Stations:</span>
                    <Chart
                        width={'500px'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={pieDataLevel00}
                        chartEvents={[
                            {
                                eventName: 'select',
                                callback: ({ chartWrapper }) => {
                                    const chart = chartWrapper.getChart()
                                    const selection = chart.getSelection()
                                    if (selection.length === 1) {
                                        const [selectedItem] = selection
                                        console.log(pieDataLevel0[selectedItem.row])
                                        getInfoLevel1(pieDataLevel0[selectedItem.row])
                                    }
                                },
                            },
                        ]}
                        options={{
                            //title: 'Stations:',
                            // Just add this option
                            is3D: true,
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    />
                </div>
                {pieDataLevel1.length > 0 && isLoadedLevel1 && <div style={{ height: 380 }} className={styles.partframe}>
                    <span style={myStyle}>Submenu:</span>
                    <Pie3D data={pieDataLevel1} config={{ onClick: (index) => getDetailsInfo(pieDataLevel1[index]) }} />
                    <div >
                        {pieDataLevel1.map((item, index) => {
                            return <span className="row"><p style={{ borderRadius: 5, backgroundColor: item.color, width: 10, height: 10 }}></p>{item.title}</span>
                        })}
                    </div>
                </div>}
            </div>}
            {isLoaded && <div className={styles.rowC}>
                <div id="chart" className={styles.partframe}>
                    <span style={myStyle}>Stations:</span>
                </div>
                {pieDataLevel1.length > 0 && isLoadedLevel1 && <div style={{ height: 380 }} className={styles.partframe}>
                    <span style={myStyle}>Submenu:</span>
                    <Pie3D data={pieDataLevel1} config={{ onClick: (index) => getDetailsInfo(pieDataLevel1[index]) }} />
                    <div >
                        {pieDataLevel1.map((item, index) => {
                            return <span className="row"><p style={{ borderRadius: 5, backgroundColor: item.color, width: 10, height: 10 }}></p>{item.title}</span>
                        })}
                    </div>
                </div>}
            </div>}
            {isLoaded && <div className={styles.rowC}>
                <div id="chartDonut" className={styles.partframe}>
                    <span style={myStyle}>Stations:</span>
                    {/* <PieChart
                        label={({ dataEntry }) => dataEntry.value + "(" + Math.round(dataEntry.percentage) + '%)'}
                        labelStyle={{ fontSize: "5" }}
                        data={pieDataLevel0}
                        lengthAngle={-360} animate
                        labelPosition={80}
                        onClick={(e, index) => getInfoLevel1(pieDataLevel0[index])}
                    /> */}
                </div>
                {pieDataLevel1.length > 0 && isLoadedLevel1 && <div style={{ height: 380 }} className={styles.partframe}>
                    <span style={myStyle}>Submenu:</span>
                    <Pie3D data={pieDataLevel1} config={{ onClick: (index) => getDetailsInfo(pieDataLevel1[index]) }} />
                    <div >
                        {pieDataLevel1.map((item, index) => {
                            return <span className="row"><p style={{ borderRadius: 5, backgroundColor: item.color, width: 10, height: 10 }}></p>{item.title}</span>
                        })}
                    </div>
                </div>}
            </div>}
            {
                isLoaded && <div className="container-fluid row text-center" style={{ margin: 0 }}>
                    <Modal
                        show={showModal}
                        //show={true}
                        onHide={() => setModalCancel()}
                        className="text-left"
                        size="xl"
                    >
                        <Modal.Body>
                            <div className={stylesTrace.topModal}>
                                <div className={stylesTrace.closeButton} onClick={setModalCancel}>X</div>
                                <div className={stylesTrace.largeLabelCenter} style={{ textAlign: "center" }}>Report {traceDetailsList[0]?.departmentName} From {traceDetailsList[0]?.stationName}</div>
                                {/* <div className={styles.ProductBarcode92Center}>WorkOrder Barcode : {traceDetailsList[0]?.productBarcodeNumber}</div> */}
                            </div>
                            {
                                <div className="container-fluid" > {isDetalsLoaded && <MyTable columns={columnsDetails} noSearch="true" rows={traceDetailsList} />}</div>
                            }
                        </Modal.Body>
                    </Modal>

                </div>
            }
        </div >
    )
}