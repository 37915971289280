import React, { useEffect } from "react";
import { requestServer } from "../helpers";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import Example from "./Example";
import moment from "moment";

export default function ImageViewer(props) {
  const { productBarcodeNumber } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [msg, setMsg] = React.useState();
  const [dataList, setDataList] = React.useState([]);
  const [selectedSrc, setSelectedSrc] = React.useState();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  useEffect(() => {
    initData(productBarcodeNumber);
  }, []);
  const initData = async (productBarcodeNumber) => {
    console.warn(
      "strat",
      "/api/Product/GetWorkOrderAttachments?productBarcodeNumber=" +
        productBarcodeNumber
    );
    setIsLoading(true);
    try {
      var result = await requestServer(
        "/api/Product/GetWorkOrderAttachments?productBarcodeNumber=" +
          productBarcodeNumber,
        null,
        "GET"
      );
      console.log(result);
      if (result.isSuccessStatus) {
        if (result.data?.length > 0) setDataList(result.data);
        else alert("Not found any picture for WO: "+productBarcodeNumber);
      } else setMsg("Error in data received!");
    } catch (e) {
      console.warn(e);
      setMsg(e?.message);
    }
    setIsLoading(false);
  };
  function onExiting() {
    setAnimating(true);
  }

  function onExited() {
    setAnimating(false);
  }

  function next() {
    if (animating) return;
    const nextIndex = activeIndex === dataList.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  function previous() {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? dataList.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  function goToIndex(newIndex) {
    if (animating) return;
    setActiveIndex(newIndex);
  }
  const slides = dataList.map((item) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={item.id}>
        <p>{ "Scanned by " + item.userEntity.userName} on {moment(item.createDate).locale("en").format("MMMM-DD-yyyy HH:mm:ss")}</p>
        <img
          src={item.productImage}
          alt={item.createDate}
          
          style={{ height: 600 }}
        />
        {/* <CarouselCaption
          captionHeader={ "Scanned by " + item.userEntity.userName}
          captionText={moment(item.createDate).locale("en").format("MMMM-DD-yyyy HH:mm:ss")}
        /> */}
        {/* captionText={item.id} captionHeader={item.createDate} */}
      </CarouselItem>
    );
  });
   return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      allowFullScreen
      interval="120000"
    >
      <CarouselIndicators
        items={dataList}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}
