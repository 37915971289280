import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ label, onCHange }) => {
    return (
        <div>
            <strong>{label}{" "}</strong>
            <div className="toggle-switch">
                <input type="checkbox" className="checkbox" onChange={onCHange}
                    name={label} id={label} />
                <label className="label" htmlFor={label}>
                    <span className="inner" />
                    <span className="switch" />
                </label>
            </div>
        </div>
    );
};

export default ToggleSwitch;
