import React, { Component, useState, useEffect } from 'react';
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
//import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
//import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
//import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


import { authHeader, Loading, requestServer } from '../helpers';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { Button } from 'reactstrap';
import { faCheckCircle, faEye, faPlusCircle, faTimesCircle, faBackspace, faRecycle, faExchangeAlt, faExclamationTriangle, faReceipt, faShippingFast } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyTable from './MyTable';
import styles from '../styles/mystyle.module.css';



export function Balance(props) {
    //const [myrequests, setmyrequests] = useState([{ temperatureC: 0 }, { temperatureC: 0 }, { temperatureC: 0 }, { temperatureC: 0 }, { temperatureC: 0 }]);
    const [myrequests, setmyrequests] = useState([]);
    const [loading, setloading] = useState(false);
    const [user, setUser] = useState("yshakoury")
    const [reload, setReoload] = useState(true)



    useEffect(() => {
        populateWeatherData();
    }, [user]);

    const renderForecastsTable = (myrequests) => {
        return (
            <div>
                <div class="h-100 row align-items-center">
                    <div className="row text-center" style={{ margin: "auto", padding: "1%" }}>
                        <input value={user} type="text" className="form-control col-md-2" onChange={(e) => setUser(e.currentTarget.value)} />
                        <button className="col-md-2" style={{ marginLeft: "1%" }} title="Set User" onClick={() => {
                            setReoload(false);
                            setTimeout(() => {
                                setReoload(true);
                            }, 100);
                        }} className="btn btn-primary">Set User</button>
                        <button className="col-md-2" style={{ marginLeft: "1%" }} title="Set User" onClick={() => {
                            setUser("reza2009")
                            setReoload(false);
                            setTimeout(() => {
                                setReoload(true);
                            }, 100);
                        }} className="btn btn-warning">Reza</button>
                        <button className="col-md-2" style={{ marginLeft: "1%" }} title="Set User" onClick={() => {
                            setUser("doost2009")
                            setReoload(false);
                            setTimeout(() => {
                                setReoload(true);
                            }, 100);
                        }} className="btn btn-danger">Dooste Reza</button>
                        <button className="col-md-2" style={{ marginLeft: "1%" }} title="Set User" onClick={() => {
                            setUser("yshakoury")
                            setReoload(false);
                            setTimeout(() => {
                                setReoload(true);
                            }, 100);
                        }} className="btn btn-primary">Karkhane</button>
                        <button className="col-md-2" style={{ marginLeft: "1%" }} title="Set User" onClick={() => {
                            setUser("yousef2009")
                            setReoload(false);
                            setTimeout(() => {
                                setReoload(true);
                            }, 100);
                        }} className="btn btn-info">Yousef</button>
                    </div>
                </div>
                {/* {reload &&  <MyTable columns={columns} caption={"Saved List"} rows={[]} remoteData={{ address: "/WeatherForecast/GetBalance/?user=" + user }} />} */}
                {reload && myrequests?.length > 0 && <div>
                    <div className={styles.label}>
                        ارزش دارایی ها (ریال)
                    </div>
                    <div className={styles.bigblue}>
                        {myrequests[0].temperatureC.toLocaleString()}
                    </div>
                    <div className={styles.label}>
                        ارزش دلار (ریال)
                    </div>
                    <div className={styles.bigblue}>
                        {myrequests[1].temperatureC.toLocaleString()}
                    </div>
                    <div className={styles.label}>
                        ارزش بیت کوین (دلار)
                    </div>
                    <div className={styles.bigblue}>
                        {myrequests[2].temperatureC.toLocaleString()}
                    </div>
                    <div className={styles.label}>
                        ارزش بیت کوین (ریال)
                    </div>
                    <div className={styles.bigblue}>
                        {myrequests[3].temperatureC.toLocaleString()}
                    </div>
                    <div className={styles.label}>
                    (BTC) موجودی استخر
                    </div>
                    <div className={styles.bigblue}>
                        {myrequests[4].temperatureC}
                    </div>
                </div>
                }
            </div>
        )
    }

    const populateWeatherData = async () => {
        try {
            //return;
            var data = await requestServer("/WeatherForecast/GetBalance/?user=" + user);
            setmyrequests(data);
            setloading(false);

        }
        catch (e) {
            setloading(false);
            props.history.push("/login");
            console.log(e);
        }
    }

    function contents() {
        let contents = loading
            ? Loading()
            : renderForecastsTable(myrequests);
        //contents = <div>test</div>;
        return (
            <div>
                {contents}
            </div>
        );
    }
    return (
        <div>{contents()}</div>
    )
}