import { createBrowserHistory } from "history";
import { appStateChanged } from "../redux/Actions";
import { authHeader } from "../pages/_helpers";
import { config } from "../helpers";
import { connect } from "react-redux";
import { showLoader, hideLoader, showAlert } from '../redux/Actions';
import { store } from "../redux/Store";
import { Redirect } from 'react-router-dom'
import confirm from "reactstrap-confirm";
import React, { Component } from 'react';


export const errorType = "#D32F2F";
export const infoType = "#7D5FB2";
export const warningType = "#FFA000";
export const successType = "#43A047";



export const MenuType = {
    MENU: "MENU",
    PLACE_VIEW: "PLACE_VIEW"
};
export const RoleName = {
    ADMIN: "ADMIN",
    SUPERVISOR: "SUPERVISOR",
    SCANNER: "SCANNER",
    CUSTOMERSERVICE: "CUSTOMERSERVICE",
    CLIENT: "CLIENT",
    MANAGEMENT: "MANAGEMENT",
    OFFICEUSER: "OFFICEUSER"
};
export const ReasonChangeList = [{ id: 1, title: "Client Change" }, { id: 2, title: "Human Error" }, { id: 3, title: "To Check" }, { id: 4, title: "Wait for Backup" }];

export const fileToB64 = files => {
    return new Promise((resolve, reject) => {
        let file = files.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000) + " kB",
                base64: reader.result,
                file: file
            };
            resolve(fileInfo);
        };
        reader.onerror = reject;
    });
};
const history = createBrowserHistory();

export const requestServer = async (address, data = null, method = "GET") => {
    const requestOptions = {
        method: method,
        headers: authHeader(),
        body: data
    };
    store.dispatch(showLoader());
    try {
        return await fetch(config.apiUrl + address, requestOptions)
            .then((response) => {
                store.dispatch(hideLoader());
                console.log(response.status);
                if (response.status == 403) {
                    store.dispatch(showAlert("You can not access this page", "danger"));
                    //history.goBack();
                    return false;
                }
                else if (response.status == 401) {
                    store.dispatch(showAlert("Please Login First", "GO_LOGIN"));
                    return;
                }
                else if (response.status == 404) {
                    store.dispatch(showAlert("Not connected to network"));
                    history.push("/");
                }
                else if (response.status == 400 || response.status == 500) {
                    response.json().then((err) => {
                        store.dispatch(showAlert("Error " + "\r\n" + JSON.stringify(err), "danger"));
                        //alert(err.title ?? err.message);
                    });
                    return;
                }
                return response.json()
            })
            .then((data) => {
                store.dispatch(hideLoader());
                return data;
            })
            .catch((error) => {
                store.dispatch(hideLoader());
                console.log(error);
                throw error;
            });
    }
    catch (e) {
        //store.dispatch(showAlert("خطای شبکه: " + e));
        return;
    }
};
export const Loading = () => {
    return (
        <div className="form-group text-center mb-4">
            <label htmlFor="errors" style={{ color: "red" }}>Please wait . . .</label>
            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
    )
}
export const Confirm = async (msg) => {
    let result = await confirm({
        title: (
            <div></div>
        ),
        message: msg ?? "آیا از انجام عملیات مطمئن هستید؟",
        confirmText: "بله",
        confirmColor: "danger",
        cancelColor: "warning",
        cancelText: "خیر",
    });
    return result;
}
export const getShipmentCompany = (shipmentBarcode) => {
    if (!isShipmentBarcode(shipmentBarcode))
        return "";

    if (shipmentBarcode.startsWith('B'))
        return "BTS";
    if (shipmentBarcode.startsWith('109935'))
        return "Midland";
    if (shipmentBarcode.length == 12)
        return "Purolator";
    return "Unknown";
}
export const isShipmentBarcode = (barcodeNumber) => {
    if (barcodeNumber.startsWith('B') || barcodeNumber.startsWith('109935') || (barcodeNumber.length == 12 && myCheckIsNumber(barcodeNumber)))
        return true;
    return false;
}
export const getShipmentLink = (shipmentBarcode) => {
    var company = getShipmentCompany(shipmentBarcode);
    switch (company) {
        case "BTS":
            return "http://shipnow.purolator.com/shiponline/track/PurolatorTrackE.asp?PINNO=" + shipmentBarcode;
        case "Midland":
            return "https://ship.midlandtransport.com/Tracking/TrackClientTrackings?TrackingNumber="+shipmentBarcode+"&Lang=0";
        case "Purolator":
            return "https://www.purolator.com/en/shipping/tracker?pin=" + shipmentBarcode;
    }
    return "#";
}

export const getShipmentCompanyCode = (shipmentBarcode) => {
    var company = getShipmentCompany(shipmentBarcode);
    switch (company) {
        case "BTS":
            return 1;
        case "Midland":
            return 2;
        case "Purolator":
            return 3;
    }
    return 0;
}
export const myCheckIsNumber = (data) => {
    var regex = /^\d+$/;
    var val = "";
    return regex.test(data);
    if (regex.test(data)) {
        val = regex.exec(data);
        alert(val)
        return true;
    }
    return false;
}
