import React, { Component, useState } from 'react';
import * as auth from "../services/authService";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Redirect } from "react-router-dom";
import { showLoader, hideLoader, showAlert, profileData } from '../redux/Actions'
import { connect } from "react-redux";
import styles from '../styles/loginStyle.module.css';

class TwoPage extends Component {
    username = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            admin: true, username: "", password: "",
            data: { username: "", password: "", token: "" },
            errors: "",
            isLoading: false,
            submitted: true,
            show: false,
            isLoggedIn: false,
            actionType: 'Signup',
            roles: []
        };
    }
    componentDidMount = () => {
        console.log("started");
        try {
            this.username.current.focus();
        }
        catch { }
    };
    handleSubmit = e => {
        e.preventDefault();

        // call the server
        console.log("Password:" + this.state.password);
        //const username = this.username.current.value;
        console.log("Username:", this.state.username);
        this.doSubmit();
    };
    doSubmit = async () => {
        try {
            this.setState({ isLoading: true });
            console.log("start Submit");
            const { data } = this.state;
            if (this.state.actionType == 'Signup') {
                var signupRes = await auth.signup(this.state.username, this.state.password);
                this.setState({ errors: "Code is sent to your email" });
                //this.props.showAlert("Code is sent to your email", "danger");
            }
            else {
                var loginResult = await auth.login(this.state.username, this.state.password);
                console.log("Success Login");
                this.props.saveProfileData({ username: this.state.username,roles: loginResult.roles });
                this.setState({ isLoggedIn: true, roles: loginResult.roles })
            }
        } catch (ex) {
            console.log(ex);
            try {
                this.setState({ errors: JSON.parse(ex)?.message });
            }
            catch (ex2) {
                this.props.showAlert(ex, "danger");
            }
        }
        this.setState({ isLoading: false });
    };
    // tst(){
    //     this.props.dispatch(showLoader());
    // }
    render() {
        // if (['A', 'B', 'C', 'D', 'E'].find(element => element === 'eD'))
        //     return "Yes";
        // return "no";
        if (this.state.isLoggedIn && this.state.roles.length > 0)
            if (this.state.roles.find(role => role === "SCANNER"))
                return <Redirect to={'./barcode-read'} />
            else///CUSTOMERSERVICE--SUPERVISOR     
                return <Redirect to={'./report'} />
        return (
            <div  className="row">
          jjjjjjjjjjjjjjjjjjjjjjjjjj
            </div>
        )
        return (
            <div className="auth-wrapper">

            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    showLoader: p => dispatch(showLoader()),
    hideLoader: p => dispatch(hideLoader()),
    showAlert: (p, e) => dispatch(showAlert(p, e)),
    saveProfileData: (p) => dispatch(profileData(p))
})
const mapStateToProps = state => ({ loading: false, profileData: null });
export default connect(mapStateToProps, mapDispatchToProps)(TwoPage);