import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { KalaList } from './components/KalaList';
import { Layout } from './components/Layout';
import './custom.css';
import { store } from "./redux/Store";
import AppLoader from './AppLoader.jsx';
import AlertView from './components/AlertView';
import LoginForm from './components/LoginForm';
import LogOutForm from './components/LogOutForm';
import { BarcodeRead } from './components/Barcode/BarcodeRead';
import { BarcodeScanPage } from './pages/BarcodeScanPage';
import { Balance } from './components/Balance';
import TraceBarcodeReport from './pages/Report/TraceBarcodeReport';
import { DepartmentsStatusPage } from './pages/Report/DepartmentsStatusPage';
import TwoPage from './pages/TwoPage';
import { FirstPage } from './pages/FirstPage';
import { Redirect } from 'react-router';





const AppContext = React.createContext();
export default class App extends Component {
  static displayName = App.name;
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      show: false
    }
    this.showNotification = this.showNotification.bind(this);
  }
  componentDidMount() {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }
  showNotification() {
    new Notification('Hey')
  }
  render() {
    return (
      <Provider store={store}>
        <Layout style={{ color: "red" }}>
          <AlertView />
          <Route path='/departmentsStatus' component={DepartmentsStatusPage} />
          <Route path='/report' component={TraceBarcodeReport} />
          <Route path='/barcode-read' component={BarcodeScanPage} />
          <Route path='/barcode-read2' component={BarcodeRead} />
          <Route path='/kala-list' component={KalaList} />
          <Route path='/balance' component={Balance} />
          <Route path="/login" component={LoginForm} />
          <Route path="/logout" component={LogOutForm} />
          <Route path="/first" component={FirstPage} />
          <Route path="/two" component={TwoPage} />
          {/* <Route path="/" component={LoginForm} /> */}
          <Route
            exact
            path="/"
            render={() => {
              return (
                1==1 ?
                  <Redirect to="/login" /> :
                  <Redirect to="/login" />
              )
            }}
          />

          <AppLoader />
        </Layout>
      </Provider>
    );
  }
}
