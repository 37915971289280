import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { config, RoleName } from "../helpers";

import { profileData, setBadgeCount } from '../redux/Actions';
import { Badge } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBox } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import styles from '../styles/navMenuStyle.module.css';


class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      isLoggedIn: false,
    };
  }
  componentDidMount() {
    this.setState({ isLoggedIn: config.isLoggedIn });
    if (config.isLoggedIn)
      this.props.saveProfileData({ username: JSON.parse(localStorage.getItem('user'))?.username, roles: JSON.parse(localStorage.getItem('user'))?.roles });
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header style={{ margin: 0 }}>
        {1 == 1 && <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            {this.props.profileData?.username && <NavbarBrand tag={Link} to="/"><a href="/"><p className={styles.logo}></p></a></NavbarBrand>}
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                {this.props.profileData?.roles?.find(role => role === RoleName.SCANNER) && <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/barcode-read">Scan Barcode</NavLink>
                </NavItem>}
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/report">Inquiry</NavLink>
                </NavItem>
                {this.props.profileData?.roles?.find(role => role === RoleName.MANAGEMENT || role === RoleName.ADMIN) && <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/departmentsStatus">Admin Report</NavLink>
                </NavItem>}
                {/* <NavItem>
                
                  <NavLink tag={Link} className="text-dark" to="/departmentsStatus">Admin Report</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/report">Inquiery</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/barcode-read">Scan Barcode</NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/barcode-read">Start</NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/kala-list">Test</NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/balance">Balance</NavLink>
                </NavItem> */}
                {!this.props.profileData?.username && 1 == 2 && <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>
                </NavItem>}
                {this.props.profileData?.username &&
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/logout">{this.props.profileData?.username}</NavLink>
                  </NavItem>
                }
              </ul>
            </Collapse>
          </Container>
        </Navbar>}
      </header>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  saveProfileData: (p) => dispatch(profileData(p)),
  setBadgeCount: (data) => dispatch(setBadgeCount(data))
})
const mapStateToProps = state => ({
  profileData: state.application?.profileData,
  badgeCount: state.application?.badgeCount,
});
export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
