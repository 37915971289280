// import { createStore, compose, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import rootReducer from './../Reducers'

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default function configureStore() {
//   return createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));
// }



import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger'

import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import rootReducer from './../Reducers'
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['User']
}

const middlewares = [thunk];
middlewares.push(logger);

const persistedReducer = persistReducer(persistConfig, rootReducer)

export let store = createStore(persistedReducer, applyMiddleware(...middlewares))
export let persistor = persistStore(store)



