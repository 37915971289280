import { Input } from '@material-ui/core'
import React, { Component } from 'react'
import BarcodeReader from 'react-barcode-reader'

export class BarcodeRead extends Component {
  constructor(props){
    super(props)
    this.state = {
      result: 'No result',
    }

    this.handleScan = this.handleScan.bind(this)
  }
  handleScan(result){
    if(result){
      this.setState({ result })
    }
  }
  handleError(err){
    console.error(err)
  }
  render(){
    return(
      <div>
        <Input type="text" />
        <button type="button" value="sss">test</button>
        <BarcodeReader
          onError={this.handleError}
          onScan={this.handleScan}
          
          />
        <p>{this.state.result}</p>
      </div>
    )
  }
}