import React, { Component, useState } from 'react';
import * as auth from "../services/authService";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Redirect } from "react-router-dom";
import { showLoader, hideLoader, showAlert, profileData } from '../redux/Actions'
import { connect } from "react-redux";
import styles from '../styles/loginStyle.module.css';
import ReCAPTCHA from "react-google-recaptcha";
import { RoleName } from '../helpers';
import { NoCaptchaIP } from '../pages/_constants';
import MathCaptcha from './MathCaptcha';
const recaptchaRef = React.createRef();
class LoginForm extends Component {
    username = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            admin: true, username: "", password: "",
            data: { username: "", password: "", token: "" },
            errors: "",
            isLoading: false,
            submitted: true,
            show: false,
            isLoggedIn: false,
            actionType: 'Signup',
            roles: [],
            hideCaptcha:false,
            reloadCaptcha:false,
            validCaptcha:false
        };
    }
    componentDidMount = () => {
        console.log("started");
        try {
            this.setState({hideCaptcha: window.location.href.includes(NoCaptchaIP)});
            this.username.current.focus();
        }
        catch { }
    };
    onChangeCaptcha = e => {
        console.log("Captcha value:", e);
    };
    reGenerateCaptcha=()=>{
        this.setState({reloadCaptcha:true});
        setTimeout(() => {
            this.setState({reloadCaptcha:false});
        }, 100);
      }
    handleSubmit = e => {
        e.preventDefault();

        // call the server
        console.log("Password:" + this.state.password);
        //const username = this.username.current.value;
        console.log("Username:", this.state.username);
        this.doSubmit();
    };
    doSubmit = async () => {
        try {
            this.setState({ errors: "" });
            // if(!this.state.hideCaptcha)
            // {
            //     const recaptchaValue = recaptchaRef.current.getValue();
            //     if (recaptchaValue === "" || recaptchaValue === undefined) {
            //         this.setState({ errors: "CAPTCHA incorrect" });
            //         return;
            //     }
            // }
            if(!this.state.hideCaptcha && !this.state.validCaptcha){
              this.setState({ errors: "CAPTCHA incorrect" });
              this.reGenerateCaptcha();
              return;
            }
            if (this.state.username === '' || this.state.username === undefined) {
                this.setState({ errors: "Please input username" });
                return;
            }
            this.setState({ isLoading: true });
            console.log("start Submit");
            const { data } = this.state;
            if (this.state.actionType == 'Signup') {
                var signupRes = await auth.signup(this.state.username, this.state.password);
                this.setState({ errors: "Code is sent to your email" });
                //this.props.showAlert("Code is sent to your email", "danger");
            }
            else {
                var loginResult = await auth.login(this.state.username, this.state.password);
                console.log("Success Login");
                this.props.saveProfileData({ username: this.state.username, roles: loginResult.roles });
                this.setState({ isLoggedIn: true, roles: loginResult.roles })
            }
        } catch (ex) {
            console.log(ex);
            try {
                this.setState({ errors: JSON.parse(ex)?.message });
            }
            catch (ex2) {
                this.props.showAlert(ex, "danger");
            }
        }
        this.setState({ isLoading: false });
    };
    // tst(){
    //     this.props.dispatch(showLoader());
    // }
    render() {
        // if (['A', 'B', 'C', 'D', 'E'].find(element => element === 'eD'))
        //     return "Yes";
        // return "no";

        /////New Auto Redirect Before Login
        //alert(this.props.profileData?.roles?.length)
        if (this.props.profileData?.roles?.length > 0)
            if (this.props.profileData?.roles?.find(role => role === "SCANNER"))
                return <Redirect to={'./barcode-read'} />
            else///CUSTOMERSERVICE--SUPERVISOR     
                return <Redirect to={'./report'} />
        /////


        if (this.state.isLoggedIn && this.state.roles.length > 0)
            if (this.state.roles.find(role => role === "SCANNER"))
                return <Redirect to={'./barcode-read'} />
            else///CUSTOMERSERVICE--SUPERVISOR     
                return <Redirect to={'./report'} />
        return (
            <div class="container-fluid">
                <div className={styles.Signinup} className="row">
                    <div className={styles.boxLogin}>
                        <p className={styles.logo}></p>
                        <div className={styles.WelcomeToApplicatin}>Welcome To Tracking System</div>
                        <div className={styles.PleaseSignin}>Please Sign in to your Account</div>
                        <div>
                            <form onSubmit={this.handleSubmit}>
                                <div>
                                    {this.state.isLoading && <div className="form-group text-center mb-4">
                                        <label htmlFor="errors" style={{ color: "red" }}>Please Wait . . .</label>
                                        <img style={{ width: "20px", height: "20px" }} src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>}
                                    {this.state.errors && < div style={{ fontSize: "small" }} className="text-left alert alert-danger alert-dismissible fade show" role="alert">
                                        <strong>{this.state.errors}!</strong>
                                    </div>}
                                    <div className={styles.boxUsername}>
                                        <label className={styles.labelUsername} htmlFor="username">Username :</label>
                                        <input className={styles.contentUsername} required ref={this.username} placeholder="Please input your username" id="username" value={this.state.username} onChange={(e) => this.setState({ username: e.currentTarget.value })} type="text" className="form-control" />
                                    </div>
                                    <div className={styles.boxUsername}>
                                        <label className={styles.labelUsername} htmlFor="password">Verification Code :</label>
                                        <input required className="form-control" id="password" type="password" placeholder="Input your verification code" value={this.state.password} onChange={(e) => this.setState({ password: e.currentTarget.value })} />
                                    </div>
                                    <div className={styles.ResendVerifyCode} className="row">
                                        <div className="col-2">
                                            <a onClick={e => {
                                                this.setState({ actionType: 'Signup' })
                                                this.doSubmit(e)
                                            }} href="#">SignUp</a>
                                        </div>
                                        <div className="text-right col-10">
                                            <a onClick={e => {
                                                this.setState({ actionType: 'Signup' })
                                                this.doSubmit(e)
                                            }} href="#">Resend Verify Code</a>
                                        </div>
                                    </div>
                                    {/* {!this.state.hideCaptcha && <div className={styles.captcha}>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey="6Lf5nQ8bAAAAAEuGN5qcMHxhAg0YS0sWYTsq0IKF"
                                            onChange={this.onChangeCaptcha}
                                        /></div>} */}
                                        {!this.state.hideCaptcha && !this.state.reloadCaptcha && <div style={{marginTop:10,maxWidth:'88%'}}><MathCaptcha onChangeStatus={(status)=>this.setState({validCaptcha:status})}/></div>}
                                    <button onClick={e => {
                                        this.setState({ actionType: 'Login' })
                                    }} className={styles.loginButton}><span className={styles.loginButtonText}>Login</span></button>
                                    {/* <div>
                                    <button onClick={e => {
                                        this.setState({ actionType: 'Signup' })
                                    }} className={styles.loginButton}><span className={styles.loginButtonText}>Signup</span></button>
                                </div> */}
                                </div>
                            </form>
                        </div>

                    </div>
                    {/* <div className={styles.imgLogin}><p  /></div> */}
                    <div className={styles.parentImgLogin}><p className={styles.imgLogin}></p></div>

                </div></div >
        )
        return (
            <div className="auth-wrapper">

            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    showLoader: p => dispatch(showLoader()),
    hideLoader: p => dispatch(hideLoader()),
    showAlert: (p, e) => dispatch(showAlert(p, e)),
    saveProfileData: (p) => dispatch(profileData(p))
})
const mapStateToProps = state => ({
    loading: false,
    profileData: state.application?.profileData,
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);